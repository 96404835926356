import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {MoostRulesOverviewComponent} from './moost-rules-overview/moost-rules-overview.component';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {RulesRoutingModule} from './rules-routing.module';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTabsModule} from '@angular/material/tabs';
import {NotificationsModule} from '../notifications-module/notifications.module';
import {MatChipsModule} from '@angular/material/chips';
import {MatCardModule} from '@angular/material/card';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MoostRulesConfigurationComponent} from './moost-rules-configuration/moost-rules-configuration.component';
import {NgxEchartsModule} from 'ngx-echarts';
import {MoostChartsModule} from '../moost-charts/moost-charts.module';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {SharedModule} from '../shared-module/shared.module';
import {MoostDatasetListComponent} from './moost-dataset-list/moost-dataset-list.component';
import {MoostDatasetChipComponent} from './moost-dataset-chip/moost-dataset-chip.component';
import {MoostDatasetDetailComponent} from './moost-dataset-detail/moost-dataset-detail.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {
  RuleLanguageFormFieldComponent
} from './monaco-rule-language-editor/rule-language-form-field/rule-language-form-field.component';
import {MonacoEditorModule} from "ngx-monaco-editor-v2";
import {MoostRulesDataGraphComponent} from './moost-rules-data-graph/moost-rules-data-graph.component';
import {RuleLanguage} from './monaco-rule-language-editor/rule-language/rule-language';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MoostBuildingsSelectorComponent} from './moost-buildings-selector/moost-buildings-selector.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MoostRulesErrorComponent} from './moost-rules-error/moost-rules-error.component';
import {MoostRuleImportDialogComponent} from './moost-rule-import-dialog/moost-rule-import-dialog.component';
import {MoostRulesFilterComponent} from './moost-rules-filter/moost-rules-filter.component';


@NgModule({
  declarations: [
    MoostRulesFilterComponent,
    MoostBuildingsSelectorComponent,
    MoostRuleImportDialogComponent,
    MoostRulesOverviewComponent,
    MoostRulesConfigurationComponent,
    MoostDatasetListComponent,
    MoostDatasetChipComponent,
    RuleLanguageFormFieldComponent,
    MoostDatasetDetailComponent,
    MoostRulesDataGraphComponent,
    MoostRulesErrorComponent
  ],
  imports: [
    MatDialogModule,
    MatMomentDateModule,
    RulesRoutingModule,
    CommonModule,
    MatDatepickerModule,
    MatTableModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatTabsModule,
    NotificationsModule,
    MatChipsModule,
    MatCardModule,
    DragDropModule,
    NgxEchartsModule,
    MoostChartsModule,
    MatDividerModule,
    SharedModule,
    NgOptimizedImage,
    MatCheckboxModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    MonacoEditorModule.forRoot(RuleLanguage.EDITOR_CONFIG),
    MatMenu,
    MatMenuTrigger,
    MatMenuItem,
  ],
  exports: [
    MoostRulesOverviewComponent,
    MoostRulesDataGraphComponent
  ]
})
export class RulesModule {
}
