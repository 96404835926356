<div class="column-chart-container">
  <app-moost-building-energy-chart [endTimestamp]="endTimestamp"
                                   [events]="events$ | async"
                                   [isLoading]="isLoading"
                                   [startTimestamp]="startTimestamp"
                                   class="chart-item"
                                   title="Power Metrics">

  </app-moost-building-energy-chart>
</div>
