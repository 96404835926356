<div class="page-content">
  <div class="card-container">
    <mat-card appearance="outlined" class="card-item">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon>badge</mat-icon>
        </div>
        <mat-card-title>Organization</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-divider></mat-divider>
        <div class="attribute-container">
          <div class="attribute-name-item">ID</div>
          <div class="attribute-value-item">{{ customer?.id }}</div>
        </div>
        <div class="attribute-container">
          <div class="attribute-name-item">Name</div>
          <div class="attribute-value-item">{{ customer?.name }}</div>
        </div>
        <div class="attribute-container">
          <div class="attribute-name-item">Subscription</div>
          <div class="attribute-value-item">{{ customer?.subscription }}</div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined" class="card-item">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon>email</mat-icon>
        </div>
        <mat-card-title>User</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-divider></mat-divider>
        <div class="attribute-container">
          <div class="attribute-name-item">Username</div>
          <div class="attribute-value-item">{{ authTokenService.getUsername() }}</div>
        </div>
        <div class="attribute-container">
          <div class="attribute-name-item">Permissions</div>
          <div class="attribute-value-item">
            <li *ngFor="let permission of authTokenService.getPermissions()">{{ permission }}</li>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="authTokenService.hasPermission(Permission.ADMIN_MOOST)" appearance="outlined" class="card-item">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon>policy</mat-icon>
        </div>
        <mat-card-title>Access Token</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-divider></mat-divider>
        <div class="attribute-container">
          <pre>{{ JSON.stringify(authTokenService.getAccessToken(), null, 2) }}</pre>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="authTokenService.hasPermission(Permission.ADMIN_MOOST)" appearance="outlined" class="card-item">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon>badge</mat-icon>
        </div>
        <mat-card-title>ID Token</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-divider></mat-divider>
        <div class="attribute-container">
          <pre>{{ JSON.stringify(authTokenService.getIdToken(), null, 2) }}</pre>
        </div>
      </mat-card-content>
    </mat-card>

  </div>
</div>
