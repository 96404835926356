import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {IEvent} from '../../events-module/events.models';
import {MoostChartsService} from '../../moost-charts/moost-charts.service';

@Component({
  selector: 'app-moost-building-water-chart',
  templateUrl: './moost-buildings-water-chart.component.html'
})
export class MoostBuildingsWaterChartComponent implements OnChanges {
  @Input() events: IEvent[];
  @Input() title: string;
  @Input() isLoading: boolean;
  @Input() endTimestamp: number;
  @Input() startTimestamp: number;

  private buildingWaterTemperatureSubject = new BehaviorSubject({});
  public buildingWaterTemperature$ = this.buildingWaterTemperatureSubject.asObservable();

  constructor(private chartService: MoostChartsService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.transformEvents(this.events);
  }

  transformEvents(events: IEvent[]): void {
    if (events) {
      let heatpumpWaterTemperature: IEvent[];
      let waterHeaterWaterTemperature: IEvent[];

      if (this.endTimestamp - this.startTimestamp <= 432000000) {
        heatpumpWaterTemperature = this.chartService.groupDataForChart(this.events, 'WATER_TEMPERATURE', 'hour', 'Heatpump', 'max');
        waterHeaterWaterTemperature = this.chartService.groupDataForChart(this.events, 'WATER_TEMPERATURE', 'hour', 'Water Heater', 'max');
      } else {
        heatpumpWaterTemperature = this.chartService.groupDataForChart(this.events, 'WATER_TEMPERATURE', 'day', 'Heatpump', 'max');
        waterHeaterWaterTemperature = this.chartService.groupDataForChart(this.events, 'WATER_TEMPERATURE', 'day', 'Water Heater', 'max');
      }

      this.buildingWaterTemperatureSubject.next({
        legend: {
          data: ['Heatpump', 'Water Heater']
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        xAxis: {
          type: 'category',
          data: Object.keys(waterHeaterWaterTemperature)
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: 'Heatpump',
          type: 'line',
          areaStyle: {},
          data: Object.values(heatpumpWaterTemperature),
          smooth: true
        }, {
          name: 'Water Heater',
          type: 'line',
          areaStyle: {},
          data: Object.values(waterHeaterWaterTemperature),
          smooth: true
        }]
      });
    }
  }
}
