import {Component, Input, OnInit} from '@angular/core';
import {
  Dataset,
  DatasetDialogData,
  DatasetType,
  IDataset,
  IDatasetDialogResult,
  secondsToDurationString
} from '../rules.models';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MoostDatasetDetailComponent} from '../moost-dataset-detail/moost-dataset-detail.component';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DatasetsService} from '../datasets.service';

@Component({
  selector: 'app-moost-dataset-list',
  templateUrl: './moost-dataset-list.component.html',
  styleUrls: ['./moost-dataset-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MoostDatasetListComponent
    }
  ]
})
export class MoostDatasetListComponent implements OnInit, ControlValueAccessor {
  private static readonly ONE_WEEK_IN_MILLISECONDS: number = 604800000;

  @Input()
  from: number = Date.now() - MoostDatasetListComponent.ONE_WEEK_IN_MILLISECONDS;
  @Input()
  to: number = Date.now();
  datasets: IDataset[] = [];
  touched: boolean = false;
  disabled: boolean = false;

  constructor(public dialog: MatDialog, public datasetsService: DatasetsService) {
  }

  public ngOnInit(): void {
    this.datasetsService.datasetsSource.subscribe((datasets: IDataset[]): void => {
      this.datasets = datasets;
    });
  }

  public openDatasetDetail(dataset: IDataset = new Dataset()): void {
    this.markAsTouched();
    if (!this.disabled) {
      if (!this.datasets) {
        this.datasets = [];
      }

      const dialogConfig: MatDialogConfig<DatasetDialogData> = new MatDialogConfig();
      dialogConfig.width = '500px';
      dialogConfig.data = {
        dataset: (dataset ? dataset : new Dataset()),
        forbiddenDatasetNames: this.getForbiddenDatasetNames(dataset, this.datasets),
        from: this.from,
        to: this.to
      };

      this.dialog.open(MoostDatasetDetailComponent, dialogConfig).afterClosed()
        .subscribe((datasetDialogResult: IDatasetDialogResult): void => {
          if (datasetDialogResult && datasetDialogResult.dataset) {
            const indexOfDataset: number = this.getIndexOfDatasetByName(datasetDialogResult.datasetKey, this.datasets);
            if (indexOfDataset == -1) {
              this.datasets.push(datasetDialogResult.dataset);
            } else {
              this.datasets[indexOfDataset] = datasetDialogResult.dataset;
            }
            this.onChange(this.datasets);
            this.datasetsService.datasetsSource.next(this.datasets);
          }
        });
    }
  }

  removeDataset(name: string): void {
    this.markAsTouched();
    if (!this.disabled) {
      const index: number = this.getIndexOfDatasetByName(name, this.datasets);
      this.datasets.splice(index, 1);
      this.onChange(this.datasets);
      this.datasetsService.datasetsSource.next(this.datasets);
    }
  }

  getForbiddenDatasetNames(currentDataset: IDataset, datasets: IDataset[]): string[] {
    if (datasets) {
      // When we edit a dataset ensure that the name of itself is not in the forbiddenDatasetNames
      return datasets.filter((d: IDataset) => d.name !== currentDataset.name)
        // Create array of all existing dataset names which then are prohibited
        .map((d: IDataset) => d.name)
    } else {
      return [];
    }
  }

  getIndexOfDatasetByName(datasetName: string, datasets: IDataset[]): number {
    if (datasets) {
      return datasets.findIndex((d: IDataset): boolean => d.name === datasetName)
    } else {
      return -1;
    }
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(datasets: IDataset[]): void {
    this.datasets = datasets;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  getSummary(dataset: IDataset): string {
    let summary: string = "";
    if (DatasetType[dataset.type] === DatasetType.SINGLEVALUE) {
      summary = "Single value";
    } else if (DatasetType[dataset.type] === DatasetType.TIMESERIES) {
      summary = `Time series of ${secondsToDurationString(dataset.timeframe)}`;
    }
    return summary;
  }

  private onChange = (datasets: IDataset[]): void => {
  };

  private onTouched = (): void => {
  }
}
