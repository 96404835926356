import {Component, OnDestroy, OnInit} from '@angular/core';
import {CustomerService} from '../../shared-module/customer.service';
import {Customer} from '../../shared-module/customer.model';
import {Permission} from '../../auth-token-module/auth-token.models';
import {Subscription} from 'rxjs';
import {MoostHeaderService} from '../../moost-header/moost-header.service';
import {AuthTokenService} from '../../auth-token-module/auth-token.service';

@Component({
  selector: 'app-moost-users-profile',
  templateUrl: './moost-users-profile.component.html',
  styleUrls: ['./moost-users-profile.component.scss']
})

export class MoostUsersProfileComponent implements OnInit, OnDestroy {
  protected readonly Permission = Permission;
  protected readonly JSON = JSON;
  protected customer: Customer;
  private customerSubscription: Subscription;

  constructor(
    protected authTokenService: AuthTokenService,
    private customerService: CustomerService,
    private headerService: MoostHeaderService) {
    this.headerService.setTitle('Profile');
  }

  ngOnInit(): void {
    this.customerSubscription = this.customerService.getCustomer()
      .subscribe(customer => this.customer = customer);
  }

  ngOnDestroy(): void {
    this.customerSubscription?.unsubscribe();
  }
}
