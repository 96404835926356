import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {IBuilding} from './buildings.models';
import {Observable, of} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class BuildingsService {
  buildingsUrl: string = `${environment.apiUrl}/buildings`;

  constructor(private cookieService: CookieService, private httpClient: HttpClient) {
  }

  saveFavoriteCustomerBuildingIds(customerId: string, customerBuildingId: Set<string>): void {
    this.cookieService.set(this.getCookieNameBuildingsFavorites(customerId),
      JSON.stringify(Array.from(customerBuildingId)));
  }

  loadFavoriteCustomerBuildingIds(customerId: string): Set<string> {
    const json = this.cookieService.get(this.getCookieNameBuildingsFavorites(customerId));
    if (json) {
      return new Set<string>(JSON.parse(json));
    } else {
      return new Set<string>([]);
    }
  }

  getBuilding(customerBuildingId: string): Observable<IBuilding> {
    return this.httpClient.get<IBuilding>(`${this.buildingsUrl}/${customerBuildingId}/v1`);
  }

  getBuildings(): Observable<IBuilding[]> {
    return this.httpClient.get<IBuilding[]>(`${this.buildingsUrl}/v1`);
  }

  getCustomerBuildingIdsBasedOnEventTypes(eventTypes: string[], fromMilliseconds: number, toMilliseconds: number): Observable<String[]> {
    if (eventTypes?.length > 0) {
      return this.httpClient.get<String[]>(`${this.buildingsUrl}/customerBuildingIds/v1`, {
        params: new HttpParams()
          .set('eventTypes', eventTypes.join(','))
          .set('from', Math.round(fromMilliseconds / 1000))
          .set('to', Math.round(toMilliseconds / 1000))
      });
    }
    return of(undefined);
  }

  getCustomerBuildingIdsBasedOnEventSources(deviceTypes: string[], fromMilliseconds: number, toMilliseconds: number): Observable<String[]> {
    if (deviceTypes?.length > 0) {
      return this.httpClient.get<String[]>(`${this.buildingsUrl}/customerBuildingIds/v1`, {
        params: new HttpParams()
          .set('deviceTypes', deviceTypes.join(','))
          .set('from', Math.round(fromMilliseconds / 1000))
          .set('to', Math.round(toMilliseconds / 1000))
      });
    }
    return of(undefined);
  }

  updateBuilding(building: IBuilding): Observable<IBuilding> {
    return this.httpClient.put<IBuilding>(`${this.buildingsUrl}/v1`, building);
  }

  private getCookieNameBuildingsFavorites(customerId: string): string {
    return `moost-buildings-favorites-${customerId}`;
  }
}
