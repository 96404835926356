import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {IEvent} from '../../events-module/events.models';
import {MoostChartsService} from '../../moost-charts/moost-charts.service';

@Component({
  selector: 'app-moost-building-energy-chart',
  templateUrl: './moost-buildings-energy-chart.component.html'
})
export class MoostBuildingsEnergyChartComponent implements OnChanges {
  @Input() events: IEvent[];
  @Input() title: string;
  @Input() isLoading: boolean;
  @Input() endTimestamp: number;
  @Input() startTimestamp: number;

  private buildingPowerGenerationSubject = new BehaviorSubject({});
  public buildingPowerGeneration$ = this.buildingPowerGenerationSubject.asObservable();

  constructor(private chartsService: MoostChartsService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.transformEvents(this.events);
  }

  transformEvents(events: IEvent[]): void {
    if (events) {
      let buildingPowerGeneration: IEvent[];
      let buildingPowerConsumption: IEvent[];
      let buildingPowerGenerationForecast: IEvent[];
      let buildingGridPowerConsumption: IEvent[];
      if (this.endTimestamp - this.startTimestamp <= 432000000) {
        buildingPowerGeneration = this.chartsService.groupDataForChart(this.events, 'POWER_GENERATION', 'hour', 'GATEWAY', 'sum');
        buildingPowerConsumption = this.chartsService.groupDataForChart(this.events, 'POWER_CONSUMPTION', 'hour', 'GATEWAY', 'sum');
        buildingGridPowerConsumption = this.chartsService.groupDataForChart(this.events, 'GRID_POWER_CONSUMPTION', 'hour', 'GATEWAY', 'sum');
        buildingPowerGenerationForecast = this.chartsService.groupDataForChart(this.events, 'POWER_GENERATION_FORECAST_1H', 'hour', 'GATEWAY', 'max');
      } else {
        buildingPowerGeneration = this.chartsService.groupDataForChart(this.events, 'POWER_GENERATION', 'day', 'GATEWAY', 'sum');
        buildingPowerConsumption = this.chartsService.groupDataForChart(this.events, 'POWER_CONSUMPTION', 'day', 'GATEWAY', 'sum');
        buildingGridPowerConsumption = this.chartsService.groupDataForChart(this.events, 'GRID_POWER_CONSUMPTION', 'day', 'GATEWAY', 'sum');
        buildingPowerGenerationForecast = this.chartsService.groupDataForChart(this.events, 'POWER_GENERATION_FORECAST_24H', 'day', 'GATEWAY', 'max');
      }

      this.buildingPowerGenerationSubject.next({
        legend: {
          data: ['Power Generation', 'Power Consumption', 'Grid Power Consumption', 'Forecasted Power Generation']
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        xAxis: {
          type: 'category',
          data: Object.keys(buildingPowerGeneration)
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: 'Power Consumption',
          type: 'line',
          areaStyle: {},
          data: Object.values(buildingPowerConsumption),
          smooth: true,
          label: {
            formatter: '{b} \n {d}%'
          }
        }, {
          name: 'Power Generation',
          type: 'line',
          areaStyle: {},
          data: Object.values(buildingPowerGeneration),
          smooth: true,
          label: {
            formatter: '{b} \n {d}%'
          }
        }, {
          name: 'Grid Power Consumption',
          type: 'line',
          areaStyle: {},
          data: Object.values(buildingGridPowerConsumption),
          smooth: true,
          label: {
            formatter: '{b} \n {d}%'
          }
        }, {
          name: 'Forecasted Power Generation',
          type: 'line',
          areaStyle: {
            opacity: 0.1
          },
          data: Object.values(buildingPowerGenerationForecast),
          smooth: true,
          label: {
            formatter: '{b} \n {d}%'
          }
        }]
      });
    }
  }
}
