import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {NotificationsFilter} from '../notifications.models';
import {BehaviorSubject, EMPTY, Observable, Subscription} from 'rxjs';
import {CountByInteraction, NotificationsService} from '../notifications.service';
import {catchError, finalize} from 'rxjs/operators';
import {TitleCasePipe} from '@angular/common';

@Component({
  selector: 'app-moost-notifications-per-interaction-chart',
  templateUrl: './moost-notifications-per-interaction-chart.component.html'
})
export class MoostNotificationsPerInteractionChartComponent implements OnChanges, OnDestroy {
  @Input() filter: NotificationsFilter;
  @Input() title: string;
  isLoading: boolean;
  subscription: Subscription;
  private chartOptionsSubject: BehaviorSubject<{}> = new BehaviorSubject({});
  chartOptions$: Observable<{}> = this.chartOptionsSubject.asObservable();

  constructor(private notificationsService: NotificationsService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadCountByInteractions();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  applyOnChart(counts: CountByInteraction[]): void {
    if (counts) {
      const titleCasePipe = new TitleCasePipe();
      const chartData: any[] = [];
      counts.forEach((entry: CountByInteraction) => {
        chartData.push({name: titleCasePipe.transform(entry.interaction), value: entry.count});
      });
      this.chartOptionsSubject.next({
        tooltip: {
          trigger: 'item'
        },
        series: [{
          type: 'pie',
          radius: '60%',
          data: chartData,
          label: {
            show: true,
            position: 'outside',
            overflow: 'truncate',
            bleedMargin: '10',
            formatter: '{b} \n {d}%'
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      });
    }
  }

  private loadCountByInteractions(): void {
    if (this.filter) {
      this.isLoading = true;
      this.subscription = this.notificationsService.getCountByInteractions(this.filter)
        .pipe(
          catchError(() => EMPTY),
          finalize(() => this.isLoading = false)
        )
        .subscribe((counts: CountByInteraction[]) => this.applyOnChart(counts));
    }
  }
}
