import {Injectable} from '@angular/core';
import {IEvent} from '../events-module/events.models';

@Injectable({
  providedIn: 'root',
})
export class MoostChartsService {
  constructor() {
  }

  public groupDataForChart(events: IEvent[], type: string, groupBy?: string, source?: string, aggregate?: string): any {
    return events.filter(
      e => e.type === type && e.source === source
    ).reduce((p, e) => {
      const dateFormat: Date = new Date(e.timestamp * 1000);
      let group: string;

      if (groupBy === 'hour') {
        group = dateFormat.getDate() + '.' + (dateFormat.getMonth() + 1) + '.' +
          dateFormat.getFullYear() + ' ' + dateFormat.getHours() + ':00';
      } else if (groupBy === 'day') {
        group = dateFormat.getDate() + '.' + (dateFormat.getMonth() + 1) + '.' + dateFormat.getFullYear();
      }

      if (!p.hasOwnProperty(group)) {
        p[group] = e.value;
      } else {
        if (aggregate === 'sum') {
          p[group] += e.value;
        } else if (aggregate === 'max') {
          p[group] = Math.max(p[group], e.value);
        }
      }

      return p;
    }, []);
  }
}
