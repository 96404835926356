import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {ActionQualifier, NotificationsFilter} from '../notifications.models';
import {BehaviorSubject, EMPTY, Observable, Subscription} from 'rxjs';
import {EChartsOption} from 'echarts/types/dist/echarts';
import {SeriesOption} from 'echarts';
import {catchError, finalize} from 'rxjs/operators';
import {CountByInteractionPerTime, NotificationsService} from '../notifications.service';

@Component({
  selector: 'app-moost-notifications-interaction-rate-chart',
  templateUrl: './moost-notifications-interaction-rate.component.html'
})
export class MoostNotificationsInteractionRateComponent implements OnChanges, OnDestroy {
  @Input() filter: NotificationsFilter;
  @Input() title: string;
  isLoading: boolean;
  subscription: Subscription;
  private readonly DURATION_1D_IN_MILLIS: number = 24 * 3600 * 1000;
  private chartOptionsSubject: BehaviorSubject<EChartsOption> = new BehaviorSubject({});
  chartOptions$: Observable<EChartsOption> = this.chartOptionsSubject.asObservable();

  constructor(private notificationsService: NotificationsService) {
  }

  private static groupByTime(counts: CountByInteractionPerTime[]): Map<number, CountByInteractionPerTime[]> {
    const interactionsPerDay: Map<number, CountByInteractionPerTime[]> = new Map();
    counts.forEach((it: CountByInteractionPerTime) => {
      let interactions: CountByInteractionPerTime[] = interactionsPerDay.get(it.time);
      if (!interactions) {
        interactions = [];
        interactionsPerDay.set(it.time, interactions);
      }
      interactions.push(it);
    });
    return interactionsPerDay;
  }

  ngOnChanges(): void {
    this.loadCountByInteractionsPerTime();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  applyOnChart(counts: CountByInteractionPerTime[]): void {
    if (counts) {
      const series: SeriesOption[] = [];
      const days: number[] = [...new Set(counts.map((it: CountByInteractionPerTime) => it.time))].sort((n1, n2) => n1 - n2);
      const interactionsPerDay: Map<number, CountByInteractionPerTime[]> = MoostNotificationsInteractionRateComponent.groupByTime(counts);
      Object.keys(ActionQualifier).forEach((interaction: string): void => {
        const data: (Date | number)[][] = [];
        for (let day of days) {
          const interactions: CountByInteractionPerTime[] = interactionsPerDay.get(day);
          const count = interactions.find((it: CountByInteractionPerTime) => it.interaction === interaction)?.count || 0;
          const countAll = interactions.map((it: CountByInteractionPerTime) => it.count).reduce((a, c) => a + c, 0)
          data.push([new Date(day), Math.round(count / countAll * 10000) / 100]); // round to 2 decimal places
        }
        series.push({
          name: interaction,
          type: "line",
          areaStyle: {},
          stack: "total",
          data: data
        })
      });

      this.buildChart(series)
    }
  }

  private buildChart(series: SeriesOption[]): void {
    this.chartOptionsSubject.next({
      legend: {
        orient: 'horizontal',
        textStyle: {
          width: 100,
          overflow: 'truncate'
        }
      },
      dataZoom: [
        {
          type: 'slider',
          start: 0,
          end: 100,
          zlevel: -1
        }
      ],
      tooltip: {
        trigger: 'axis'
      },
      series: series,
      xAxis: {
        type: 'time',
        axisLabel: {
          formatter: "{dd}.{MM}.{yyyy}"
        }
      },
      yAxis: {
        id: "3NumberState",
        type: 'value',
        position: 'left',
        axisLabel: {
          formatter: '{value} %'
        }
      }
    });
  }

  private loadCountByInteractionsPerTime(): void {
    if (this.filter) {
      this.isLoading = true;
      this.subscription = this.notificationsService.getCountByInteractionsPerTime(this.filter, this.DURATION_1D_IN_MILLIS)
        .pipe(
          catchError(() => EMPTY),
          finalize(() => this.isLoading = false)
        )
        .subscribe((counts: CountByInteractionPerTime[]) => this.applyOnChart(counts));
    }
  }
}
