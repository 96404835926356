<div class="data-graph-container">

  <div *ngIf="!hasError && !isLoading() && this.series.length !== 0" [options]="options | async" echarts></div>

  <div *ngIf="hasError" class="data-graph-message-container">
    <mat-icon aria-hidden="false" aria-label="Icon showing a cross which indicates an error" color="warn"
              fontIcon="error">
    </mat-icon>
    <h2>Error loading graph</h2>
    <div>Try to refresh your browser</div>
  </div>

  <div *ngIf="this.datasets?.length > 0 && isLoading()" class="data-graph-message-container">
    <mat-spinner>
    </mat-spinner>
    <h2>Loading Data</h2>
    <div>Collecting all events and notifications</div>
  </div>

  <div *ngIf="this.series.length === 0 && !hasError && !isLoading()" class="data-graph-message-container">
    <mat-icon aria-hidden="false" aria-label="Icon representing a json object" color="primary"
              fontIcon="data_object">
    </mat-icon>
    <h2>No data found</h2>
    <div>Add datasets, enlarge date range or select another building</div>
  </div>

  <div *ngIf="isSimulationRunning" class="data-graph-overlay">
    <div class="data-graph-overlay-message mat-elevation-z8">
      <mat-spinner>
      </mat-spinner>
      <h2>Simulation in progress</h2>
    </div>
  </div>
</div>
<div *ngIf="isRuleInactive" class="info-text">
  <a [routerLink]="['/buildings/', customerBuildingId, 'edit']" aria-label="Edit"
     class="more-button" mat-icon-button>
    <mat-icon color="primary">edit</mat-icon>
  </a>
  <span>The rule is inactivate for the selected building.</span>
</div>

@if (isHelplineVisible) {
  <div class="help-line-term-container">
    <div (click)="isHelpLineFormVisible = !isHelpLineFormVisible" class="help-line-term-title-row-container">
      Help Line
      <div class="help-line-term-title-middle-container">Draw a help line by entering a term</div>
      <div class="help-line-term-title-right-container">
        <mat-spinner *ngIf="isHelpLineLoading" class="help-line-term-spinner"></mat-spinner>
        <mat-icon color="primary">query_stats</mat-icon>
        <mat-icon *ngIf="!isHelpLineFormVisible">expand_more</mat-icon>
        <mat-icon *ngIf="isHelpLineFormVisible">expand_less</mat-icon>
      </div>
    </div>
    <form [formGroup]="helpLineForm">
      <div [@toggleHelpLineForm]="isHelpLineFormVisible ? 'open' : 'closed'" class="help-line-term-form-container">
        <mat-form-field appearance="outline" class="monaco-form-field">
          <mat-label>Graph Help Line Term</mat-label>
          <app-rule-language-form-field
            (validTermChange)="changedHelpLineTerm($event)"
            [allowedResultStructures]="[TermStructure.SCALAR, TermStructure.GROUPED_SCALAR]"
            [allowedResultTypes]="[TermType.BOOLEAN, TermType.NUMBER, TermType.TIME, TermType.TIMESPAN, TermType.EVENT]"
            formControlName="helpLineTermFormField"
            placeholder="Draw a help line by entering a term">
          </app-rule-language-form-field>
        </mat-form-field>
        <mat-error *ngIf="helpLineError" class="data-graph-message-container"><p>{{ helpLineError }}</p></mat-error>
      </div>
    </form>
  </div>
}
