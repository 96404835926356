import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {IPushNotification, NotificationsFilter} from './notifications.models';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private static readonly PUSH_NOTIFICATIONS_URL: string = `${environment.apiUrl}/pushnotifications`;

  constructor(private httpClient: HttpClient) {
  }

  renderDeliveryStatusReason(reason: string): string {
    return reason?.replaceAll("_", " ").toLowerCase();
  }

  getNotifications(startTimestamp: number, endTimestamp: number, ruleId?: string, buildingId?: string): Observable<IPushNotification[]> {
    let params: HttpParams = new HttpParams()
      .set('startTimestamp', startTimestamp)
      .set('endTimestamp', endTimestamp);

    if (ruleId) {
      params = params.set("ruleId", ruleId);
    }

    if (buildingId) {
      params = params.set('buildingId', buildingId);
    }

    return this.httpClient.get<IPushNotification[]>(`${NotificationsService.PUSH_NOTIFICATIONS_URL}/v2`, {
      params
    });
  }

  sendTestNotificationFulfillment(customerBuildingId: string, ruleId: string, notification: IPushNotification): Observable<void> {
    return this.httpClient.post<void>(`${NotificationsService.PUSH_NOTIFICATIONS_URL}/fulfillment/v1`, notification, {
      params: new HttpParams()
        .set('customerBuildingId', customerBuildingId)
        .set('ruleId', ruleId)
    });
  }

  getNotificationsCount(filter: NotificationsFilter): Observable<number> {
    return this.httpClient.get<number>(`${NotificationsService.PUSH_NOTIFICATIONS_URL}/filter/count/v1`, {
      params: this.buildParams(filter)
    });
  }

  getNotificationsPage(filter: NotificationsFilter,
                       sortField: string = 'createdAtTimeMillis',
                       sortOrder: string = 'desc',
                       pageNumber: number = 1,
                       pageSize: number = 100): Observable<IPushNotification[]> {
    return this.httpClient.get<IPushNotification[]>(`${NotificationsService.PUSH_NOTIFICATIONS_URL}/filter/v1`, {
      params: this.buildParams(filter)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
        .set('sortField', sortField)
        .set('sortDirection', sortOrder)
    });
  }

  getCountByRules(filter: NotificationsFilter): Observable<CountByRule[]> {
    return this.httpClient.get<CountByRule[]>(`${NotificationsService.PUSH_NOTIFICATIONS_URL}/filter/count/rules/v1`, {
      params: this.buildParams(filter)
    });
  }

  getCountByRulesPerTime(filter: NotificationsFilter, timespan: number): Observable<CountByRulePerTime[]> {
    return this.httpClient.get<CountByRulePerTime[]>(`${NotificationsService.PUSH_NOTIFICATIONS_URL}/filter/count/rules/timespan/v1`, {
      params: this.buildParams(filter).set("timespan", timespan)
    });
  }

  getCountByDeliveryStatusReasons(filter: NotificationsFilter): Observable<CountByDeliveryStatusReason[]> {
    return this.httpClient.get<CountByDeliveryStatusReason[]>(`${NotificationsService.PUSH_NOTIFICATIONS_URL}/filter/count/delivery-status-reasons/v1`, {
      params: this.buildParams(filter)
    });
  }

  getCountByInteractions(filter: NotificationsFilter): Observable<CountByInteraction[]> {
    return this.httpClient.get<CountByInteraction[]>(`${NotificationsService.PUSH_NOTIFICATIONS_URL}/filter/count/interactions/v1`, {
      params: this.buildParams(filter)
    });
  }

  getCountByInteractionsPerTime(filter: NotificationsFilter, timespan: number): Observable<CountByInteractionPerTime[]> {
    return this.httpClient.get<CountByInteractionPerTime[]>(`${NotificationsService.PUSH_NOTIFICATIONS_URL}/filter/count/interactions/timespan/v1`, {
      params: this.buildParams(filter).set("timespan", timespan)
    });
  }

  private buildParams(filter: NotificationsFilter): HttpParams {
    let params: HttpParams = new HttpParams()
      .set('startTimestamp', filter.startTimestampMillis)
      .set('endTimestamp', filter.endTimestampMillis);
    if (filter.customerBuildingIds) {
      params = params.set('customerBuildingId', filter.customerBuildingIds.join(','));
    }
    if (filter.ruleIds) {
      params = params.set('ruleId', filter.ruleIds.join(','));
    }
    if (filter.deliveryStatuses) {
      params = params.set('deliveryStatus', filter.deliveryStatuses.join(','));
    }
    if (filter.interactions) {
      params = params.set('interaction', filter.interactions.join(','));
    }
    return params;
  }
}

export class CountByRule {
  ruleId: string;
  count: number;
}

export class CountByRulePerTime {
  ruleId: string;
  time: number;
  count: number;
}

export class CountByDeliveryStatusReason {
  deliveryStatus: string;
  reason: string;
  count: number;
}

export class CountByInteraction {
  interaction: string;
  count: number;
}

export class CountByInteractionPerTime {
  interaction: string;
  time: number;
  count: number;
}
