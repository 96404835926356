import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {BuildingsService} from '../../buildings-module/buildings.service';
import {CookieService} from 'ngx-cookie-service';
import {AuthTokenService} from '../../auth-token-module/auth-token.service';
import {IDataset} from '../rules.models';
import * as moment from 'moment/moment';
import {EMPTY, forkJoin, Observable, ObservedValueOf, of, Subscription} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {MatSelectChange} from '@angular/material/select';
import {Building, IBuilding} from '../../buildings-module/buildings.models';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
  selector: 'app-moost-buildings-selector',
  templateUrl: './moost-buildings-selector.component.html',
  styleUrls: ['./moost-buildings-selector.component.scss']
})
export class MoostBuildingsSelectorComponent implements OnInit, OnChanges, OnDestroy {
  private static readonly COOKIE_NAME_SELECTED_BUILDING: string = 'moost_selected_building';
  readonly FILTER_DATASET_SCOPED_BUILDINGS: string = "dataset-scoped-buildings";
  readonly FILTER_FAVORITE_BUILDINGS: string = "favorite-buildings";
  readonly FILTER_EARLY_ADOPTER_BUILDINGS: string = "early-adopter-buildings";
  @Input() selectedCustomerBuildingId: string;
  @Input() datasets: IDataset[];
  @Output() selectedCustomerBuildingIdChange: EventEmitter<string> = new EventEmitter<string>();
  customerBuildingIds: string[];
  isLoadingBuildings: boolean = false;
  buildingFilter: string[] = [this.FILTER_DATASET_SCOPED_BUILDINGS];
  private loadingBuildingsSubscription: Subscription;
  private favoriteCustomerBuildingIds: Set<string>;
  private buildings: IBuilding[];

  constructor(private buildingsService: BuildingsService,
              private router: Router,
              private route: ActivatedRoute,
              private cookieService: CookieService,
              private authTokenService: AuthTokenService) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams: Params) => {
      this.readRouteQueryParameters(queryParams);
    });
    this.favoriteCustomerBuildingIds = this.buildingsService.loadFavoriteCustomerBuildingIds(this.authTokenService.getCustomerId());
  }

  ngOnDestroy(): void {
    this.loadingBuildingsSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.datasets && this.buildingFilter.includes(this.FILTER_DATASET_SCOPED_BUILDINGS)) {
      this.updateSelectableBuildings();
    }
  }

  setSelectedCustomerBuildingId(selectedCustomerBuildingId: string): void {
    this.selectedCustomerBuildingId = selectedCustomerBuildingId;
    this.writeRouteQueryParameters();
    this.cookieService.set(
      MoostBuildingsSelectorComponent.COOKIE_NAME_SELECTED_BUILDING, this.selectedCustomerBuildingId
    );
    this.selectedCustomerBuildingIdChange.emit(this.selectedCustomerBuildingId);
  }

  @HostListener('window:keyup.control.k', ['$event'])
  @HostListener('window:keyup.control.j', ['$event'])
  selectNextBuilding(event: KeyboardEvent): void {
    event.preventDefault();
    const indexOfSelectedElement: number = this.customerBuildingIds.findIndex((id: string): boolean => {
      return id === this.selectedCustomerBuildingId;
    });

    if (event.key === 'k') {
      if (indexOfSelectedElement < this.customerBuildingIds.length - 1) {
        this.setSelectedCustomerBuildingId(this.customerBuildingIds[indexOfSelectedElement + 1]);
      }
    } else {
      if (indexOfSelectedElement > 0) {
        this.setSelectedCustomerBuildingId(this.customerBuildingIds[indexOfSelectedElement - 1]);
      }
    }
  }

  isFavoriteBuilding(customerBuildingId: string): boolean {
    return this.favoriteCustomerBuildingIds.has(customerBuildingId);
  }

  isEarlyAdopterBuilding(customerBuildingId: string): boolean {
    return this.buildings?.filter((building: IBuilding) => building.isEarlyAdopter)
      ?.map((building: IBuilding) => building.customerBuildingId).includes(customerBuildingId);
  }

  toggleFavoriteBuilding(customerBuildingId: string): void {
    if (this.isFavoriteBuilding(customerBuildingId)) {
      this.favoriteCustomerBuildingIds.delete(customerBuildingId);
    } else {
      this.favoriteCustomerBuildingIds.add(customerBuildingId);
    }
    this.buildingsService.saveFavoriteCustomerBuildingIds(this.authTokenService.getCustomerId(), this.favoriteCustomerBuildingIds);
  }

  applyBuildingFilter($event: MatSelectChange): void {
    this.buildingFilter = $event.value;
    this.writeRouteQueryParameters();
    this.updateSelectableBuildings();
  }

  private readRouteQueryParameters(queryParams: Params): void {
    const paramBuildingFilter: string = queryParams['buildingFilter'];
    if (paramBuildingFilter) {
      this.buildingFilter = paramBuildingFilter.split(",");
    }
    const paramBuilding: string = queryParams['building'];
    if (paramBuilding) {
      this.setSelectedCustomerBuildingId(paramBuilding);
    }
  }

  private writeRouteQueryParameters(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        buildingFilter: this.buildingFilter.join(","),
        building: this.selectedCustomerBuildingId
      },
      queryParamsHandling: 'merge',
    });
  }

  private updateSelectableBuildings(): void {
    const eventTypes: string[] = this.datasets?.flatMap((dataset: IDataset) => dataset.event_types);
    const eventFrom: number = moment().subtract({days: 1}).toDate().getTime();
    const eventTo: number = moment().toDate().getTime();
    this.isLoadingBuildings = true;
    this.loadingBuildingsSubscription = forkJoin({
      allBuildings: this.buildings ? of(this.buildings) : this.buildingsService.getBuildings(),
      dataScopedBuildingIds: this.buildingFilter.includes(this.FILTER_DATASET_SCOPED_BUILDINGS) ? this.buildingsService.getCustomerBuildingIdsBasedOnEventTypes(eventTypes, eventFrom, eventTo) : of([])
    }).pipe(
      catchError(() => EMPTY),
      finalize(() => this.isLoadingBuildings = false)
    ).subscribe((results: {
      allBuildings: ObservedValueOf<Observable<IBuilding[]>>
      dataScopedBuildingIds: ObservedValueOf<Observable<string[]>>;
    }) => {
      this.buildings = results?.allBuildings;
      let customerBuildingIds: string[] = this.buildings.map((building: Building) => building.customerBuildingId);
      if (this.buildingFilter.includes(this.FILTER_FAVORITE_BUILDINGS)) {
        customerBuildingIds = customerBuildingIds.filter((id: string) => this.favoriteCustomerBuildingIds.has(id));
      }
      if (this.buildingFilter.includes(this.FILTER_DATASET_SCOPED_BUILDINGS)) {
        customerBuildingIds = customerBuildingIds.filter((id: string) => results.dataScopedBuildingIds?.includes(id));
      }
      if (this.buildingFilter.includes(this.FILTER_EARLY_ADOPTER_BUILDINGS)) {
        customerBuildingIds = customerBuildingIds.filter((id: string) => this.isEarlyAdopterBuilding(id));
      }
      this.setSelectableBuildings(customerBuildingIds);
    });
  }

  private setSelectableBuildings(buildingIds: string[]): void {
    this.customerBuildingIds = buildingIds;
    if (buildingIds?.length > 0) {
      let selectedCustomerBuildingId: string = this.cookieService.get(
        MoostBuildingsSelectorComponent.COOKIE_NAME_SELECTED_BUILDING
      );
      if (!selectedCustomerBuildingId || !buildingIds.includes(selectedCustomerBuildingId)) {
        selectedCustomerBuildingId = buildingIds[0];
      }
      this.setSelectedCustomerBuildingId(selectedCustomerBuildingId);
    }
  }
}
