import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {RulesService} from '../rules.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  Dataset,
  IDataset,
  IRule,
  Rule,
  RuleIcon,
  secondsToDurationString,
  stringDurationToSeconds
} from '../rules.models';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {DatasetsService} from '../datasets.service';
import {IPushNotification} from '../../notifications-module/notifications.models';
import {Subscription} from 'rxjs';
import {TermType} from '../monaco-rule-language-editor/validator/term-type';
import {MoostHeaderService} from '../../moost-header/moost-header.service';
import {Permission} from '../../auth-token-module/auth-token.models';
import {AuthTokenService} from '../../auth-token-module/auth-token.service';
import {TermStructure} from '../monaco-rule-language-editor/validator/term-structure';
import {
  ConfirmDialogModel,
  MoostConfirmDialogComponent
} from '../../shared-module/moost-confirm-dialog/moost-confirm-dialog.component';
import {Clipboard} from '@angular/cdk/clipboard';
import {MoostRuleImportDialogComponent} from '../moost-rule-import-dialog/moost-rule-import-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import cron from 'cron-validate';
import cronstrue from 'cronstrue';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MoostDatasetDetailComponent} from '../moost-dataset-detail/moost-dataset-detail.component';
import * as moment from 'moment';
import {TranslationService} from '../translation.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-moost-rules-configuration',
  templateUrl: './moost-rules-configuration.component.html',
  styleUrls: ['./moost-rules-configuration.component.scss'],
  providers: [DatasetsService],
  animations: [
    trigger('toggleFormVisibility', [
      state('open', style({height: '*', opacity: 1})),
      state('closed', style({height: '0', opacity: 0})),
      transition('open <=> closed', [animate('0.2s')]),
    ])
  ]
})
export class MoostRulesConfigurationComponent implements OnInit, OnDestroy {
  rule: Rule;
  activeLanguages: Map<string, string> = new Map<string, string>([
    ['en', 'English'],
    ['de', 'German'],
    ['fr', 'French']
  ]);
  actionSelection = [
    {id: 'OPENAPP', text: 'Open App', isVisibleParameter: true},
    {id: 'OPENWEB', text: 'Open Web', isVisibleParameter: true},
    {id: 'STOPDELIVERY', text: 'Do not notify again', isVisibleParameter: false},
    {id: 'DISMISS', text: 'Dismiss', isVisibleParameter: false},
  ];
  ruleForm: FormGroup;
  //Date Range selector component
  maxDate: Date = moment().endOf('day').toDate();
  startTimestampMillis: number = moment().startOf('day').subtract({days: 14}).toDate().getTime();
  selectedDateRange: FormGroup = new FormGroup({
    startDate: new FormControl(new Date(this.startTimestampMillis), [Validators.required]),
    endDate: new FormControl(new Date(this.endTimestampMillis), [Validators.required]),
  });
  isLoadingRule: boolean;
  isSimulationRunning: boolean;
  simulatedNotifications: IPushNotification[];
  loadingTitleTranslations: number;
  loadingMessageTranslations: number;
  createOrUpdateError: string;
  errorCode: string;
  selectedCustomerBuildingId: string;
  datasets: Dataset[];
  protected readonly Permission = Permission;
  protected readonly TermType = TermType;
  protected readonly TermStructure = TermStructure;
  protected readonly cronstrue = cronstrue;
  protected readonly RegExp = RegExp;
  protected readonly RuleIcon = RuleIcon;
  private ruleSubscription: Subscription;
  private routeSubscription: Subscription;
  private simulationSubscription: Subscription;
  private datasetsSubscription: Subscription;
  private allRulesSubscription: Subscription;
  private dialogSubscription: Subscription;
  private forbiddenRuleNames: string[];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private headerService: MoostHeaderService,
              private datasetsService: DatasetsService,
              private rulesService: RulesService,
              private formBuilder: FormBuilder,
              public dialog: MatDialog,
              protected authTokenService: AuthTokenService,
              private translationService: TranslationService,
              private clipboard: Clipboard,
              private snackBar: MatSnackBar) {
    this.headerService.setHeader('Rule Configuration', '/rules');
  }

  _endTimestampMillis: number = moment().toDate().getTime();

  get endTimestampMillis(): number {
    return this._endTimestampMillis;
  }

  set endTimestampMillis(endTimestampMillis: number) {
    if (endTimestampMillis > moment().toDate().getTime()) {
      this._endTimestampMillis = moment().toDate().getTime();
    } else {
      this._endTimestampMillis = endTimestampMillis
    }
  }

  ngOnInit(): void {
    this.createOrUpdateError = null;
    this.routeSubscription = this.route.params.subscribe({
      next: (params: Params): void => {
        const ruleId: string = params.id;
        if (ruleId) {
          this.loadRule(ruleId);
        } else {
          this.ruleForm = this.buildRuleForm(null);
          this.prefillFormWithDefaultValues();
        }
        this.loadForbiddenRuleNames(ruleId);
      },
      error: (error): void => {
        console.error(error);
      }
    });
    this.route.queryParams.subscribe((queryParams: Params) => {
      this.readRouteQueryParameters(queryParams);
    });
    this.datasetsSubscription = this.datasetsService.datasetsSource.subscribe((datasets: Dataset[]): void => {
      this.datasets = datasets;
    });
  }

  ngOnDestroy(): void {
    this.simulationSubscription?.unsubscribe()
    this.ruleSubscription?.unsubscribe();
    this.routeSubscription?.unsubscribe();
    this.datasetsSubscription?.unsubscribe();
    this.allRulesSubscription?.unsubscribe();
    this.dialogSubscription?.unsubscribe();
  }

  @HostListener('window:keyup.control.enter', ['$event'])
  runSimulation(): void {
    if (!this.isSimulationRunning) {
      this.isSimulationRunning = true;
      const startTimeRangeMillis: number = this.selectedDateRange.controls.startDate.value.valueOf();
      const endTimeRangeMillis: number = this.selectedDateRange.controls.endDate.value.valueOf();
      const maxTimeFrameSeconds: number = this.datasetsService.getDatasetMaxTimeframe(this.datasets);
      const ruledId: string = this.rule ? this.rule.id : null;
      const updatedRule: IRule = this.buildRule(ruledId, this.ruleForm);

      this.simulationSubscription?.unsubscribe();
      this.simulationSubscription = this.rulesService.runSimulation(updatedRule, this.selectedCustomerBuildingId,
        Math.round(startTimeRangeMillis / 1000) - maxTimeFrameSeconds,
        Math.round(endTimeRangeMillis / 1000)
      ).subscribe({
        next: (simulatedNotifications: IPushNotification[]): void => {
          this.simulatedNotifications = simulatedNotifications;
        },
        error: (error): void => {
          this.createOrUpdateError = "The simulation ended with an internal error. Try again or contact support.";
          console.error(error);
          this.isSimulationRunning = false;
        },
        complete: (): void => {
          this.createOrUpdateError = "";
          this.isSimulationRunning = false;
        }
      });
    }
  }

  public setRuleState(event: MatSlideToggleChange): void {
    this.ruleForm.patchValue({ruleState: (event.checked ? "ACTIVE" : "PAUSE")});
    //patchValue does not set the dirty and touched flag on the formControl. Need to do it manually.
    //https://github.com/angular/angular/issues/9768
    this.ruleForm.controls.ruleState.markAsDirty()
    this.ruleForm.controls.ruleState.markAsTouched()
  }

  public isTimeBasedChanged(event: MatSlideToggleChange): void {
    const conditionControl: AbstractControl = this.ruleForm.get('condition');
    const timeBasedCronControl: AbstractControl = this.ruleForm.get('timeBasedCron');
    const isTimeBased: boolean = event.checked;
    conditionControl.setValidators(this.getConditionControlValidators(isTimeBased));
    timeBasedCronControl.setValidators(this.getTimeBasedCronControlValidators(isTimeBased));
    conditionControl.updateValueAndValidity();
    timeBasedCronControl.updateValueAndValidity();
  }

  isStreakChanged(event: MatSlideToggleChange): void {
    const streakConditionControl: AbstractControl = this.ruleForm.get('streakCondition');
    const isStreak: boolean = event.checked;
    streakConditionControl.setValidators(this.getStreakConditionControlValidators(isStreak));
    streakConditionControl.updateValueAndValidity();
  }

  dateChanged(event: MatDatepickerInputEvent<Date>): void {
    if (event.value != null && this.selectedDateRange.status === "VALID") {
      this.startTimestampMillis = this.selectedDateRange.controls.startDate.value.valueOf();
      this.endTimestampMillis = moment(this.selectedDateRange.controls.endDate.value.valueOf()).endOf('day').toDate().getTime();

      this.writeRouteQueryParameters();
    }
  }

  onSubmit(): void {
    this.createOrUpdateError = null;
    const ruledId: string = this.rule ? this.rule.id : null;
    const updatedRule: IRule = this.buildRule(ruledId, this.ruleForm);

    if (ruledId) {
      this.rulesService.updateRule(updatedRule).subscribe({
        next: (): void => {
          this.loadRule(this.rule.id);
        },
        error: (error): void => {
          this.createOrUpdateError = `Update failed: ${error.message}`;
        }
      });
    } else {
      this.rulesService.addRule(updatedRule).subscribe({
          next: (rule: IRule): void => {
            if (rule?.id) {
              void this.router.navigate([`/rules/${rule.id}/edit`], {queryParamsHandling: 'merge'});
            } else {
              this.createOrUpdateError = `Add rule failed. Try again or contact support.`;
            }
          },
          error: (error): void => {
            this.createOrUpdateError = `Add rule failed: ${error.message}`;
          }
        }
      );
    }
  }

  refreshView(): void {
    // workaround to solve rendering issue of monaco editor component, when another tab is
    // selected, therefore trigger a resize event to make browser render content of
    // rule-language-form-field.
    // See e.g.: https://github.com/angular/components/issues/20340
    window.dispatchEvent(new Event('resize'));
  }

  isActionParameterVisible(formGroupName: string): boolean {
    const ruleControls = this.ruleForm['controls'];
    const notificationControls = ruleControls.notification['controls'];
    const actionControls = notificationControls[formGroupName]['controls'];
    const actionQualifier = actionControls.actionQualifier.value;
    return this.actionSelection.find(it => it.id === actionQualifier)?.isVisibleParameter;
  }

  deleteRule(): void {
    const dialogConfig: MatDialogConfig<ConfirmDialogModel> = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.data = {
      title: "Delete Rule",
      message: `Do you really want to delete the rule with name '${this.rule.name}'?`,
      confirm: "YES",
      dismiss: "NO",
      icon: "warning_amber",
      confirmColor: "warn"
    };

    this.dialog.open(MoostConfirmDialogComponent, dialogConfig).afterClosed()
      .subscribe((confirmed: boolean): void => {
        if (confirmed) {
          this.rulesService.deleteRule(this.rule.id).subscribe({
            next: (): void => {
              this.router.navigate(["rules"], {queryParamsHandling: 'merge'});
            },
            error: (error): void => {
              this.createOrUpdateError = error.message;
              console.error(error.message);
            }
          })
        }
      });
  }

  exportRule(): void {
    const exportRule: IRule = this.buildRule(this.rule?.id, this.ruleForm);
    const success: boolean = this.clipboard.copy(JSON.stringify(exportRule));
    const message: string = success ? "Rule exported to Clipboard" : "Failed to export to Clipboard";
    this.snackBar.open(message, null, {
      duration: 1000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  openImportRuleDialog(): void {
    this.dialogSubscription = this.dialog.open(MoostRuleImportDialogComponent).afterClosed()
      .subscribe((importRule: IRule): void => {
        if (importRule != null) {
          this.importRule(importRule);
          this.snackBar.open("Rule imported from Clipboard. It is deactivated for now.", null, {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "top",
          })
        }
      });
  }

  importRule(importRule: IRule): void {
    importRule.id = this.rule?.id;
    importRule.ruleState = 'PAUSE';
    this.setRuleRelatedFields(importRule);
    this.ruleForm.markAsDirty();
  }

  translateNotificationTitle(sourceLang: string): void {
    const sourceText: string = this.ruleForm.get(['notification', "title_" + sourceLang]).value;
    const targetLanguages: string[] = ['de', 'en', 'fr'].filter((lang: string) => lang !== sourceLang);
    this.loadingTitleTranslations = 0;
    for (let targetLang of targetLanguages) {
      this.loadingTitleTranslations++;
      this.translationService.translate(sourceText, sourceLang, targetLang)
        .pipe(finalize(() => this.loadingTitleTranslations--))
        .subscribe((translation: string) => {
          this.ruleForm.get(['notification', "title_" + targetLang]).setValue(translation);
        });
    }
    this.ruleForm.markAsDirty();
  }

  translateNotificationMessage(sourceLang: string): void {
    const sourceText: string = this.ruleForm.get(['notification', "message_" + sourceLang]).value;
    const targetLanguages: string[] = ['de', 'en', 'fr'].filter((lang: string) => lang !== sourceLang);
    this.loadingMessageTranslations = 0;
    for (let targetLang of targetLanguages) {
      this.loadingMessageTranslations++;
      this.translationService.translate(sourceText, sourceLang, targetLang)
        .pipe(finalize(() => this.loadingMessageTranslations--))
        .subscribe((translation: string) => {
          this.ruleForm.get(['notification', "message_" + targetLang]).setValue(translation);
        });
    }
    this.ruleForm.markAsDirty();
  }

  protected getTopicIcon(): RuleIcon {
    return RuleIcon.getTopicIcon(this.ruleForm.get('topicIcon').value);
  }

  protected getImpactIcon(): RuleIcon {
    return RuleIcon.getImpactIcon(this.ruleForm.get('impactIcon').value);
  }

  private readRouteQueryParameters(queryParams: Params): void {
    const paramStartTimestamp: number = queryParams['s'];
    if (paramStartTimestamp) {
      this.startTimestampMillis = Number(paramStartTimestamp);
    }
    const paramEndTimestamp: number = queryParams['e'];
    if (paramEndTimestamp) {
      this.endTimestampMillis = Number(paramEndTimestamp);
    }
    this.selectedDateRange = new FormGroup({
      startDate: new FormControl(new Date(this.startTimestampMillis), [Validators.required]),
      endDate: new FormControl(new Date(this.endTimestampMillis), [Validators.required]),
    });
  }

  private writeRouteQueryParameters(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {s: this.startTimestampMillis, e: this.endTimestampMillis},
      queryParamsHandling: 'merge'
    });
  }

  private loadRule(ruleId: string): void {
    this.isLoadingRule = true;
    this.ruleSubscription = this.rulesService.getRuleById(ruleId).subscribe({
        next: (rule: Rule): void => {
          if (rule && !rule.notification.actions) {
            rule.notification.actions = [];
          }
          this.setRuleRelatedFields(rule);
          this.isLoadingRule = false;
          this.errorCode = '';
        },
        error: (error): void => {
          console.error(error);
          if (error.status === 404) {
            this.errorCode = "NOT_FOUND";
          } else {
            this.errorCode = "ERROR";
          }
          this.isLoadingRule = false;
        }
      }
    )
  }

  private setRuleRelatedFields(rule: Rule): void {
    this.rule = rule;
    this.datasetsService.datasetsSource.next(this.rule.datasets);
    this.ruleForm = this.buildRuleForm(rule);
  }

  private loadForbiddenRuleNames(selectedRuleId: string): void {
    this.allRulesSubscription = this.rulesService.getAllRules().subscribe((rules: IRule[]): void => {
      this.forbiddenRuleNames = rules
        .filter((rule: IRule) => rule.id !== selectedRuleId)
        .map((rule: IRule) => rule.name);
    });
  }

  private getConditionControlValidators(isOptional: boolean): ValidatorFn[] {
    if (isOptional) {
      return [this.ruleLanguageTermValidator()];
    } else {
      return [Validators.required, this.ruleLanguageTermValidator()];
    }
  }

  private getTimeBasedCronControlValidators(isTimeBased: boolean): ValidatorFn[] {
    if (isTimeBased) {
      return [Validators.required, this.cronValidator()];
    } else {
      return [];
    }
  }

  private getStreakConditionControlValidators(isStreak: boolean): ValidatorFn[] {
    return isStreak ? [Validators.required, this.ruleLanguageTermValidator()] : null;
  }

  private buildRuleForm(rule: IRule): FormGroup {
    const ruleForm: FormGroup = this.formBuilder.group({
      name: new FormControl<string>(rule?.name, [Validators.required, this.ruleNameValidator()]),
      description: new FormControl<string>(rule?.description),
      topicIcon: new FormControl<string>(rule?.topicIcon),
      impactIcon: new FormControl<string>(rule?.impactIcon),
      ruleState: new FormControl<string>(rule?.ruleState && rule?.ruleState === 'ACTIVE' ? 'ACTIVE' : 'PAUSE'),
      datasets: new FormControl<IDataset[]>(rule?.datasets),
      isRestrictedToEarlyAdopters: new FormControl<boolean>(rule?.isRestrictedToEarlyAdopters),
      resetStateWhenMatched: new FormControl<boolean>(rule?.resetStateWhenMatched),
      isTimeBased: new FormControl<boolean>(rule?.isTimeBased),
      timeBasedCron: new FormControl<string>(rule?.timeBasedCron, this.getTimeBasedCronControlValidators(rule?.isTimeBased)),
      condition: new FormControl<string>(rule?.condition, this.getConditionControlValidators(rule?.isTimeBased)),
      isStreak: new FormControl<boolean>(!!rule?.streakCondition),
      streakCondition: new FormControl<string>(rule?.streakCondition, this.getStreakConditionControlValidators(!!rule?.streakCondition)),
      match_threshold: new FormControl<string>(rule?.match_threshold, Validators.pattern(/^\d*$/)),
      time_between_triggers_seconds: new FormControl<string>(secondsToDurationString(rule?.time_between_triggers_seconds), [Validators.pattern(MoostDatasetDetailComponent.STRING_TIME_DURATION_REGEXP), Validators.required]),
      notification: this.formBuilder.group({
        title_de: new FormControl<string>(rule?.notification?.title?.de, Validators.required),
        title_en: new FormControl<string>(rule?.notification?.title?.en, Validators.required),
        title_fr: new FormControl<string>(rule?.notification?.title?.fr), // French is not required
        message_de: new FormControl<string>(rule?.notification?.message?.de, [Validators.required, this.ruleLanguageTermValidator()]),
        message_en: new FormControl<string>(rule?.notification?.message?.en, [Validators.required, this.ruleLanguageTermValidator()]),
        message_fr: new FormControl<string>(rule?.notification?.message?.fr, [this.ruleLanguageTermValidator()]), // French is not required
        primary_action: this.formBuilder.group({
          text_de: new FormControl<string>(rule?.notification?.actions[0]?.text.de, Validators.required),
          text_en: new FormControl<string>(rule?.notification?.actions[0]?.text.en, Validators.required),
          text_fr: new FormControl<string>(rule?.notification?.actions[0]?.text.fr, Validators.required),
          actionQualifier: new FormControl<string>(rule?.notification?.actions[0]?.actionQualifier, Validators.required),
          parameter_de: new FormControl<string>(rule?.notification?.actions[0]?.parameter?.de),
          parameter_en: new FormControl<string>(rule?.notification?.actions[0]?.parameter?.en),
          parameter_fr: new FormControl<string>(rule?.notification?.actions[0]?.parameter?.fr),
        }),
        secondary_action: this.formBuilder.group({
          text_de: new FormControl<string>(rule?.notification?.actions[1]?.text.de, Validators.required),
          text_en: new FormControl<string>(rule?.notification?.actions[1]?.text.en, Validators.required),
          text_fr: new FormControl<string>(rule?.notification?.actions[1]?.text.fr, Validators.required),
          actionQualifier: new FormControl<string>(rule?.notification?.actions[1]?.actionQualifier, Validators.required),
          parameter_de: new FormControl<string>(rule?.notification?.actions[1]?.parameter?.de),
          parameter_en: new FormControl<string>(rule?.notification?.actions[1]?.parameter?.en),
          parameter_fr: new FormControl<string>(rule?.notification?.actions[1]?.parameter?.fr),
        }),
      }),
    });
    ruleForm.markAllAsTouched();
    return ruleForm;
  }

  private buildRule(ruleId: string, ruleForm: FormGroup): IRule {
    const ruleControls = ruleForm['controls'];
    const notificationControls = ruleControls.notification['controls'];
    const primaryActionControls = notificationControls.primary_action['controls'];
    const secondaryActionControls = notificationControls.secondary_action['controls'];
    return {
      id: ruleId,
      customerId: this.authTokenService.getCustomerId(),
      name: ruleControls.name.value,
      description: ruleControls.description.value,
      topicIcon: ruleControls.topicIcon.value,
      impactIcon: ruleControls.impactIcon.value,
      ruleState: ruleControls.ruleState.value,
      condition: ruleControls.condition.value,
      streakCondition: ruleControls.isStreak.value ? ruleControls.streakCondition.value : "",
      isRestrictedToEarlyAdopters: ruleControls.isRestrictedToEarlyAdopters.value,
      isTimeBased: ruleControls.isTimeBased.value,
      timeBasedCron: ruleControls.isTimeBased.value ? ruleControls.timeBasedCron.value : null,
      datasets: ruleControls.datasets.value,
      match_threshold: ruleControls.match_threshold.value,
      resetStateWhenMatched: ruleControls.resetStateWhenMatched.value,
      time_between_triggers_seconds: stringDurationToSeconds(ruleControls.time_between_triggers_seconds.value),
      notification: {
        title: {
          de: notificationControls.title_de.value,
          en: notificationControls.title_en.value,
          fr: notificationControls.title_fr.value,
        },
        message: {
          de: notificationControls.message_de.value,
          en: notificationControls.message_en.value,
          fr: notificationControls.message_fr.value,
        },
        actions: [{
          text: {
            de: primaryActionControls.text_de.value,
            en: primaryActionControls.text_en.value,
            fr: primaryActionControls.text_fr.value
          },
          actionQualifier: primaryActionControls.actionQualifier.value,
          parameter: {
            de: primaryActionControls.parameter_de.value,
            en: primaryActionControls.parameter_en.value,
            fr: primaryActionControls.parameter_fr.value
          },
        }, {
          text: {
            de: secondaryActionControls.text_de.value,
            en: secondaryActionControls.text_en.value,
            fr: secondaryActionControls.text_fr.value
          },
          actionQualifier: secondaryActionControls.actionQualifier.value,
          parameter: {
            de: secondaryActionControls.parameter_de.value,
            en: secondaryActionControls.parameter_en.value,
            fr: secondaryActionControls.parameter_fr.value
          },
        }]
      }
    };
  }

  private cronValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const cronString: string = control?.value;
      if (cronString) {
        const cronResult = cron(cronString, {
          preset: 'default',
          override: {
            useAliases: true // override preset option
          }
        });
        return cronResult.isValid() ? null : {cronFormat: cronResult.getError()};
      } else {
        return {cronFormat: "Cron-formatted entry is required when rule is time-based (see https://en.wikipedia.org/wiki/Cron)"};
      }
    };
  }

  private ruleLanguageTermValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.errors ? {isInvalidTerm: true} : null;
    };
  }

  private ruleNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const ruleName: string = control?.value?.trim();
      return ruleName && this.forbiddenRuleNames?.includes(ruleName) ? {forbiddenName: {value: ruleName}} : null;
    };
  }

  private prefillFormWithDefaultValues(): void {
    this.ruleForm.get(['time_between_triggers_seconds']).setValue('0');
    this.ruleForm.get(['notification', 'primary_action', 'actionQualifier']).setValue('OPENAPP');
    this.ruleForm.get(['notification', 'primary_action', 'text_de']).setValue("DETAILS");
    this.ruleForm.get(['notification', 'primary_action', 'text_en']).setValue("DETAILS");
    this.ruleForm.get(['notification', 'primary_action', 'text_fr']).setValue("DÉTAILS");
    this.ruleForm.get(['notification', 'secondary_action', 'actionQualifier']).setValue('STOPDELIVERY');
    this.ruleForm.get(['notification', 'secondary_action', 'text_de']).setValue("NICHT ERNEUT ANZEIGEN");
    this.ruleForm.get(['notification', 'secondary_action', 'text_en']).setValue("DON'T NOTIFY AGAIN");
    this.ruleForm.get(['notification', 'secondary_action', 'text_fr']).setValue("NE PLUS NOTIFIER");
  }
}
