<h2 mat-dialog-title>
  Import Rule from Clipboard
</h2>

<div id="dialogContent" mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>Rule to import</mat-label>
    <textarea (keyup)="enterRule($event)"
              (paste)="pasteRule($event)"
              [formControl]="ruleControl"
              [placeholder]="pasteShortCut + ': Paste rule here'"
              matInput>
    </textarea>
    @if (ruleControl.invalid) {
      <mat-error>Invalid rule</mat-error>
    }
  </mat-form-field>
</div>

