import {Component, EventEmitter, Input, OnDestroy, OnInit, Output,} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import * as moment from 'moment';
import {catchError, finalize} from 'rxjs/operators';
import {EMPTY, Subscription} from 'rxjs';
import {EventsService} from '../../events-module/events.service';
import {
  BUILDING_FILTER_STATUS_ACTIVE,
  BUILDING_FILTER_STATUS_INACTIVE,
  BuildingsFormFilter
} from './buildings-form-filter';
import {arraysEqual} from '../moost-buildings-overview/moost-buildings-overview.component';

@Component({
  selector: 'app-moost-buildings-filter',
  templateUrl: './moost-buildings-filter.component.html',
  styleUrls: ['./moost-buildings-filter.component.scss']
})

export class MoostBuildingsFilterComponent implements OnInit, OnDestroy {
  @Input() filteredCustomerBuildingIds: string[];
  @Input() favoriteCustomerBuildingIds: string[];
  @Output() applyFilterChange = new EventEmitter<BuildingsFormFilter>()
  @Output() saveFavoritesChange = new EventEmitter<string[]>()
  filterForm: FormGroup;
  buildingStatus: string[] = [
    BUILDING_FILTER_STATUS_ACTIVE,
    BUILDING_FILTER_STATUS_INACTIVE
  ]
  eventTypes: string[] = [];
  eventSources: string[] = [];
  eventFrom: number = moment().subtract({days: 1}).toDate().getTime();
  eventTo: number = moment().toDate().getTime();
  isLoadingEventTypes: boolean = false;
  isLoadingSourceTypes: boolean = false;
  private loadEventTypesSubscription: Subscription;
  private loadSourceTypesSubscription: Subscription;

  constructor(private eventsService: EventsService) {
  }

  ngOnInit(): void {
    this.filterForm = this.buildForm(new BuildingsFormFilter());
    this.loadEventTypes();
    this.loadSourceTypes();
  }

  ngOnDestroy(): void {
    this.loadEventTypesSubscription?.unsubscribe();
    this.loadSourceTypesSubscription?.unsubscribe();
  }

  applyFilter(): void {
    this.setFilter(this.buildBuildingFilter(this.filterForm, this.eventFrom, this.eventTo));
  }

  clearFilter(): void {
    this.setFilter(new BuildingsFormFilter());
  }

  saveFilteredBuildingsAsFavorites(): void {
    if (this.isFilterAndFavoritesInSync()) {
      this.saveFavoritesChange.emit([]);
    } else {
      this.saveFavoritesChange.emit(this.filteredCustomerBuildingIds);
    }
  }

  isFilterAndFavoritesInSync(): boolean {
    return arraysEqual(this.filteredCustomerBuildingIds, this.favoriteCustomerBuildingIds);
  }

  private setFilter(filter: BuildingsFormFilter): void {
    this.filterForm = this.buildForm(filter);
    this.applyFilterChange.emit(filter);
  }

  private loadEventTypes(): void {
    this.filterForm.get("eventTypes").disable();
    this.isLoadingEventTypes = true;
    this.loadEventTypesSubscription = this.eventsService.getEventTypes().pipe(
      catchError(() => EMPTY),
      finalize(() => this.isLoadingEventTypes = false)
    ).subscribe((eventTypes: string[]): void => {
      this.eventTypes = eventTypes;
      this.filterForm.get("eventTypes").enable();
    });
  }

  private loadSourceTypes(): void {
    this.filterForm.get("eventSources").disable();
    this.isLoadingSourceTypes = true;
    this.loadSourceTypesSubscription = this.eventsService.getSourceTypes().pipe(
      catchError(() => EMPTY),
      finalize(() => this.isLoadingSourceTypes = false)
    ).subscribe((eventSources: string[]): void => {
      this.eventSources = eventSources;
      this.filterForm.get("eventSources").enable();
    });
  }

  private buildForm(filter: BuildingsFormFilter): FormGroup {
    return new FormGroup({
      id: new FormControl<string>(filter?.id),
      location: new FormControl<string>(filter?.location),
      status: new FormControl<string[]>(filter?.status),
      eventTypes: new FormControl<string[]>(filter?.eventTypes),
      eventSources: new FormControl<string[]>(filter?.eventSources),
    })
  }

  private buildBuildingFilter(filterForm: FormGroup, eventFrom: number, eventTo: number): BuildingsFormFilter {
    return {
      id: filterForm.get("id").value,
      location: filterForm.get("location").value,
      status: filterForm.get("status").value,
      eventTypes: filterForm.get("eventTypes").value,
      eventSources: filterForm.get("eventSources").value,
      eventDateFrom: eventFrom,
      eventDateTo: eventTo
    }
  }
}
