import {Component, Inject, Input, LOCALE_ID, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {DataPoint, IDataset} from '../rules.models';
import {EventsService} from '../../events-module/events.service';
import {NotificationsService} from '../../notifications-module/notifications.service';
import {EChartsOption, LegendComponentOption} from 'echarts/types/dist/echarts';
import {IPushNotification} from '../../notifications-module/notifications.models';
import {IEvent} from '../../events-module/events.models';
import {LineSeriesOption, ScatterSeriesOption, SeriesOption} from 'echarts';
import {DatasetsService} from '../datasets.service';
import {YAXisOption} from 'echarts/types/dist/shared';
import {MoostRulesDataGraphAxis} from './moost-rules-data-graph.axis';
import {BuildingsService} from '../../buildings-module/buildings.service';
import {OptionDataValue} from 'echarts/types/src/util/types';
import {formatNumber} from '@angular/common';
import {RulesService} from '../rules.service';
import {TermType} from '../monaco-rule-language-editor/validator/term-type';
import {TermStructure} from '../monaco-rule-language-editor/validator/term-structure';
import {FormControl, FormGroup} from '@angular/forms';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {IBuilding} from '../../buildings-module/buildings.models';

@Component({
  selector: 'app-moost-rules-data-graph',
  templateUrl: './moost-rules-data-graph.component.html',
  styleUrls: ['./moost-rules-data-graph.component.scss'],
  animations: [
    trigger('toggleHelpLineForm', [
      state('open', style({height: '*', opacity: 1})),
      state('closed', style({height: '0', opacity: 0})),
      transition('open <=> closed', [animate('0.2s')]),
    ])
  ]
})
export class MoostRulesDataGraphComponent implements OnInit, OnChanges, OnDestroy {
  static readonly DELIVERED_NOTIFICATIONS_HEX_COLOR: string = "#2dbe60";
  static readonly DROPPED_NOTIFICATIONS_HEX_COLOR: string = "#666666";
  static readonly SIMULATED_DELIVERED_NOTIFICATIONS_HEX_COLOR: string = "#91d8a2";
  static readonly SIMULATED_DROPPED_NOTIFICATIONS_HEX_COLOR: string = "#C2C2C2";
  private static readonly DELIVERED_NOTIFICATIONS_SERIES_ID: string = "DeliveredNotifications";
  private static readonly DROPPED_NOTIFICATIONS_SERIES_ID: string = "DroppedNotifications";
  private static readonly SIMULATED_DELIVERED_NOTIFICATIONS_SERIES_ID: string = "SimulatedDeliveredNotifications";
  private static readonly SIMULATED_DROPPED_NOTIFICATIONS_SERIES_ID: string = "SimulatedDroppedNotifications";
  private static readonly NOTIFICATIONS_SERIES_NAME: string = "Notifications";
  private static readonly DELIVERY_STATUS_DROPPED: string = "DROPPED";
  private static readonly DELIVERY_STATUS_DELIVERED: string = "DELIVERED";
  private static readonly YAXIS_OFFSET: number = 60;
  @Input() title: string;
  @Input() ruleId: string;
  @Input() customerBuildingId: string;
  @Input() startTimeRangeMillis: number;
  @Input() endTimeRangeMillis: number;
  @Input() simulatedNotifications: IPushNotification[];
  @Input() isSimulationRunning: boolean;
  @Input() isHelplineVisible: boolean = true;
  hasError: boolean = false;
  isLoadingEvents: boolean;
  isLoadingNotifications: boolean;
  series: SeriesOption[] = [];
  datasets: DatasetInfo[] = [];
  isRuleInactive: boolean;
  helpLineForm: FormGroup;
  helpLineTerm: string;
  helpLineError: string;
  isHelpLineLoading: boolean;
  isHelpLineFormVisible: boolean = false;
  protected readonly TermType = TermType;
  protected readonly TermStructure = TermStructure;
  private svgPathLetterIcon: string = "path://M441.088,63.154H14.774C6.615,63.154,0,69.77,0,77.93v300.003c0,8.16,6.615,14.775,14.774,14.775h426.313 " +
    "c8.16,0,14.775-6.614,14.775-14.775V77.93C455.862,69.77,449.248,63.154,441.088,63.154z M403.394,316.659 " +
    "c6.256,5.43,6.926,14.903,1.497,21.16c-5.43,6.254-14.901,6.928-21.161,1.496c-3.876-3.364-101.683-88.252-105.452-91.523 " +
    "l-40.515,35.164c-2.82,2.448-6.326,3.672-9.832,3.672s-7.012-1.224-9.832-3.672l-40.515-35.164 " +
    "c-3.77,3.272-101.576,88.159-105.452,91.523c-6.257,5.43-15.731,4.761-21.161-1.496c-5.43-6.257-4.76-15.73,1.497-21.16 " +
    "L154.7,227.93L52.468,139.203c-6.256-5.43-6.926-14.903-1.497-21.16c5.431-6.256,14.904-6.928,21.161-1.496 " +
    "c5.07,4.4,146.594,127.231,155.799,135.22c7.972-6.919,150.305-130.451,155.799-135.22c6.256-5.431,15.731-4.762,21.161,1.496 " +
    "c5.43,6.257,4.76,15.731-1.497,21.16L301.162,227.93L403.394,316.659z"
  private notificationsSubscription: Subscription;
  private eventsSubscriptions: Subscription[] = [];
  private buildingSubscription: Subscription;
  private datasetsSubscription: Subscription;
  private simulationTermSubscription: Subscription;
  private optionsSubject: BehaviorSubject<EChartsOption> = new BehaviorSubject<EChartsOption>({});
  options: Observable<EChartsOption> = this.optionsSubject.asObservable();
  private readonly helpLineNamePrefix: string = 'Help Line';
  private readonly helpLineColor: string = '#966011';
  private useOwnHelpLineAxis: boolean;

  constructor(private eventService: EventsService,
              private buildingsService: BuildingsService,
              private notificationsService: NotificationsService,
              private datasetsService: DatasetsService,
              private rulesService: RulesService,
              @Inject(LOCALE_ID) private locale: string) {
  }

  ngOnInit(): void {
    this.datasetsSubscription = this.datasetsService.datasetsSource.subscribe({
      next: (datasets: IDataset[]): void => {
        this.datasets = datasets.map((dataset: IDataset) => new DatasetInfo(dataset));
        this.simulatedNotifications = [];
        this.drawChart();
      },
      error: (error): void => {
        console.error(error);
      }
    });
    this.helpLineForm = new FormGroup({
      helpLineTermFormField: new FormControl<string>(''),
    });
  }

  ngOnDestroy(): void {
    this.notificationsSubscription?.unsubscribe();
    this.eventsSubscriptions?.forEach((eventSubscription: Subscription) => eventSubscription.unsubscribe());
    this.buildingSubscription?.unsubscribe();
    this.datasetsSubscription?.unsubscribe();
    this.simulationTermSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customerBuildingId && !changes.customerBuildingId.firstChange) {
      this.buildingSubscription = this.buildingsService.getBuilding(changes.customerBuildingId.currentValue).subscribe(
        (building: IBuilding): void => {
          this.isRuleInactive = building.inactiveRules.includes(this.ruleId);
        }
      )
      this.simulatedNotifications = [];
      this.drawChart();
    } else if (changes.startTimeRangeMillis || changes.endTimeRangeMillis) {
      this.simulatedNotifications = [];
      this.drawChart();
    } else if (changes.simulatedNotifications) {
      this.drawSimulatedNotifications();
    }
  }

  changedHelpLineTerm(value: string): void {
    this.helpLineTerm = value;
    this.drawHelpLine();
  }

  notificationsToScatterSeriesOptions(notifications: IPushNotification[], isSimulation: boolean): ScatterSeriesOption[] {
    const notificationOptions: ScatterSeriesOption[] = [];

    const droppedNotifications: IPushNotification[] = notifications
      .sort((a: IPushNotification, b: IPushNotification): number => {
        return a.createdAtTimeMillis - b.createdAtTimeMillis
      }).filter((notification: IPushNotification): boolean => {
        return notification.delivery_status?.status === MoostRulesDataGraphComponent.DELIVERY_STATUS_DROPPED
      })
    const deliveredNotifications: IPushNotification[] = notifications
      .sort((a: IPushNotification, b: IPushNotification): number => {
        return a.createdAtTimeMillis - b.createdAtTimeMillis
      }).filter((notification: IPushNotification): boolean => {
        return notification.delivery_status?.status === MoostRulesDataGraphComponent.DELIVERY_STATUS_DELIVERED
      })

    if (deliveredNotifications.length > 0) {
      notificationOptions.push(this.getNotificationScatterSeriesOption("DELIVERED", deliveredNotifications, isSimulation));
    }

    if (droppedNotifications.length > 0) {
      notificationOptions.push(this.getNotificationScatterSeriesOption("DROPPED", droppedNotifications, isSimulation));
    }

    return notificationOptions
  }

  datasetToLineSeriesOptions(dataset: IDataset, events: IEvent[]): LineSeriesOption[] {
    const series: LineSeriesOption[] = [];
    const eventsGroupedBySourceAndDeviceId: any[] = events.reduce((result: IEvent[], event: IEvent): IEvent[] => {
      const key: string = event.source + ':' + event.deviceId
      result[key] = result[key] || [];
      result[key].push(event);
      return result;
    }, []);

    Object.keys(eventsGroupedBySourceAndDeviceId).forEach((key: string): void => {
      const eventsGroup = eventsGroupedBySourceAndDeviceId[key];
      let name: string;

      if (eventsGroup[0].deviceId) {
        name = `${dataset.name} - ${eventsGroup[0].source} - ${(eventsGroup[0].deviceId)}`
      } else {
        name = `${dataset.name} - ${eventsGroup[0].source}`
      }
      series.push({
        name: name,
        color: this.datasetsService.getHexColorStringForDataset(dataset),
        type: 'line',
        lineStyle: {
          type: this.datasetsService.getLineStyle(dataset),
        },
        yAxisId: MoostRulesDataGraphAxis.getYAxisToEventType(eventsGroup[0].type).id.toString(),
        data: eventsGroup
          .filter((e: IEvent) => !e.forecastTimestamp || e.forecastTimestamp <= Math.round(this.endTimeRangeMillis / 1000))
          .sort((a: IEvent, b: IEvent) => a.timestamp - b.timestamp)
          .map((event: IEvent): [Date, number] => {
            if (event.forecastTimestamp) {
              return [new Date(event.forecastTimestamp * 1000), event.value]
            } else {
              return [new Date(event.timestamp * 1000), event.value]
            }
          })
      });
    });

    return series;
  }

  buildOptionYAxis(): YAXisOption[] {
    const yaxis: YAXisOption[] = [];
    // dataset based events
    const yAxisDatasets: YAXisOption[] = [...new Set(
      this.datasets.map((dataset: DatasetInfo) => dataset.yAxisOption)
        .sort((a: YAXisOption, b: YAXisOption) => (a.id.toString().localeCompare(b.id.toString())))
    )];
    yAxisDatasets.forEach((yAxis: YAXisOption, index: number): void => {
      yAxis.offset = index * MoostRulesDataGraphComponent.YAXIS_OFFSET;
      yaxis.push(yAxis);
    });
    // help line
    if (this.helpLineTerm && this.useOwnHelpLineAxis) {
      yaxis.push(MoostRulesDataGraphAxis.HELP_LINE_AXIS);
    }
    // notifications
    yaxis.push(MoostRulesDataGraphAxis.NOTIFICATIONS_AXIS)
    return yaxis;
  }

  formatTooltipValue(value: OptionDataValue): string {
    if (typeof value === 'string') {
      return value;
    } else if (typeof value === 'number') {
      return formatNumber(value, this.locale, '1.0-2');
    } else if (value instanceof Date) {
      return value.toString();
    }
  }

  isLoading(): boolean {
    return this.isLoadingEvents || this.isLoadingNotifications;
  }

  private drawDatasetBasedEvents(): void {
    if (this.eventsSubscriptions) {
      this.eventsSubscriptions.forEach((eventSubscription: Subscription): void => {
        eventSubscription.unsubscribe();
      })
    }

    if (this.datasets && this.customerBuildingId) {
      this.datasets.forEach((datasetInfo: DatasetInfo): void => {
        this.isLoadingEvents = true;
        this.eventsSubscriptions.push(
          this.eventService.getEventsForCustomerBuilding(
            this.customerBuildingId,
            Math.round(this.startTimeRangeMillis / 1000),
            Math.round(this.endTimeRangeMillis / 1000),
            datasetInfo.dataset.event_types,
            datasetInfo.dataset.source_types
          ).subscribe({
            next: (events: IEvent[]): void => {
              datasetInfo.storeMinAndMax(events.map((event: IEvent) => event.value));
              const datasetsSeries: LineSeriesOption[] = this.datasetToLineSeriesOptions(datasetInfo.dataset, events);
              datasetsSeries.forEach((datasetSeries: LineSeriesOption): void => {
                this.series.push(datasetSeries)
              });
              this.updateChart();
            },
            error: (error): void => {
              console.error(error);
              this.hasError = true;
              this.isLoadingEvents = false;
            },
            complete: (): void => {
              this.isLoadingEvents = false;
            }
          })
        );
      });
    }
  }

  private removeSeriesById(id: string): void {
    const indexOfSeries: number = this.series.findIndex((series: SeriesOption): boolean => {
      return series.id === id
    });
    if (indexOfSeries > -1) {
      this.series.splice(indexOfSeries, 1);
    }
  }

  private drawSimulatedNotifications(): void {
    //As this function can be called without clearing the whole chart in advance we need to secure
    //that we do not have multiple Series from multiple Simulations.
    this.removeSeriesById(MoostRulesDataGraphComponent.SIMULATED_DELIVERED_NOTIFICATIONS_SERIES_ID);
    this.removeSeriesById(MoostRulesDataGraphComponent.SIMULATED_DROPPED_NOTIFICATIONS_SERIES_ID);

    if (this.simulatedNotifications && this.simulatedNotifications.length > 0) {
      const simulatedSeries: ScatterSeriesOption[] = this.notificationsToScatterSeriesOptions(
        this.simulatedNotifications, true
      );

      simulatedSeries.forEach((ss: ScatterSeriesOption): void => {
        this.series.push(ss);
      })
    }

    this.updateChart();
  }

  private drawHelpLine(): void {
    this.discardHelpLine();
    this.simulationTermSubscription?.unsubscribe();
    this.helpLineError = "";
    if (this.helpLineTerm) {
      this.isHelpLineLoading = true;
      this.simulationTermSubscription = this.rulesService.evaluateSimulationTerm(
        this.helpLineTerm,
        this.datasets.map((it: DatasetInfo) => it.dataset),
        this.customerBuildingId,
        Math.round(this.startTimeRangeMillis / 1000),
        Math.round(this.endTimeRangeMillis / 1000)
      ).subscribe({
        next: (dataSeries: Map<string, DataPoint[]>): void => {
          let helpLineMin: number = 0;
          let helpLineMax: number = 0;
          Object.values(dataSeries)
            .forEach((dataPoints: DataPoint[]) => {
              const values: number[] = dataPoints.map(it => it.value);
              if (values.length > 0) {
                helpLineMin = Math.min(helpLineMin, ...values);
                helpLineMax = Math.max(helpLineMin, ...values);
              }
            });
          const yAxisOption: YAXisOption = this.findSuitableHelpLineYAxisOption(helpLineMin, helpLineMax);
          this.useOwnHelpLineAxis = (yAxisOption === MoostRulesDataGraphAxis.HELP_LINE_AXIS);
          Object.keys(dataSeries)
            .sort()
            .forEach((seriesName: string) => {
              this.series.push({
                name: this.buildSeriesNameForHelpLine(seriesName),
                color: this.helpLineColor,
                type: 'line',
                symbolSize: 1,
                lineStyle: {
                  type: 'dotted',
                },
                yAxisId: yAxisOption.id.toString(),
                data: dataSeries[seriesName]
                  .sort((a: DataPoint, b: DataPoint) => a.timestamp - b.timestamp)
                  .map((dataPoint: DataPoint) => {
                    return [new Date(dataPoint.timestamp * 1000), dataPoint.value]
                  })
              });
            });
          this.updateChart();
        },
        error: (error): void => {
          this.helpLineError = "The evaluation of the help term failed.";
          console.error(error);
          this.isHelpLineLoading = false;
        },
        complete: (): void => {
          this.isHelpLineLoading = false;
        }
      })
    }
  }

  private findSuitableHelpLineYAxisOption(helpLineMin: number, helpLineMax: number): YAXisOption {
    const helpLineCategory: string = this.getScaleCategory(helpLineMin, helpLineMax);
    for (let ds of this.datasets) {
      if (this.getScaleCategory(ds.min, ds.max) === helpLineCategory) {
        return ds.yAxisOption;
      }
    }
    return MoostRulesDataGraphAxis.HELP_LINE_AXIS;
  }

  private getScaleCategory(min: number, max: number): string {
    const size: number = Math.max(Math.abs(min), Math.abs(max));
    if (size > 1000) {
      return "L";
    } else if (size > 100) {
      return "M";
    } else if (size > 10) {
      return "S"
    } else {
      return "XS";
    }
  }

  private discardHelpLine(): void {
    if (this.series?.length) {
      for (let i = this.series.length - 1; i >= 0; i--) {
        const labelName: string = this.series[i].name.toString();
        if (labelName.startsWith(this.helpLineNamePrefix)) {
          this.series.splice(i, 1);
        }
      }
    }
  }

  private buildSeriesNameForHelpLine(seriesName: string): string {
    seriesName = seriesName.charAt(0).toUpperCase() + seriesName.substring(1);
    return this.helpLineNamePrefix + ' - ' + seriesName.replace(':', ' - ');
  }

  private drawNotifications(): void {
    if (this.notificationsSubscription) {
      this.notificationsSubscription.unsubscribe();
    }

    if (this.ruleId && this.customerBuildingId) {
      this.isLoadingNotifications = true;
      this.notificationsSubscription = this.notificationsService.getNotifications(
        this.startTimeRangeMillis, this.endTimeRangeMillis, this.ruleId, this.customerBuildingId
      ).subscribe({
        next: (pushNotifications: IPushNotification[]): void => {
          if (pushNotifications && pushNotifications.length > 0) {
            this.notificationsToScatterSeriesOptions(pushNotifications, false).forEach(
              (scatterSeriesOption: ScatterSeriesOption): void => {
                this.series.push(scatterSeriesOption);
              })
          }
          this.updateChart();
        },
        error: (error): void => {
          console.error(error);
          this.hasError = true;
          this.isLoadingNotifications = false;
        },
        complete: (): void => {
          this.isLoadingNotifications = false;
        }
      });
    }
  }

  private buildOptionLegend(): LegendComponentOption {
    return {
      type: "scroll",
      left: "7%",
      right: "2%",
      width: "90%",
      textStyle: {
        color: "#666666",
        rich: {
          title: {
            fontWeight: "bold",
          },
          subtitle: {
            fontSize: 11,
            padding: [4, 0, 0, 0]
          }
        }
      },
      data: this.series.map((series: SeriesOption): string => {
        return series.name.toString();
      }),
      formatter: (seriesName: string): string => {
        const splitSeriesName: string[] = seriesName.split(" - ");
        if (splitSeriesName.length == 2) {
          return `{title|${splitSeriesName[0]}} \n{subtitle|${splitSeriesName[1]}}`;
        } else {
          return `{title|${splitSeriesName[0]}} \n{subtitle|${splitSeriesName[1]}} \n{subtitle|${splitSeriesName[2]}}`;
        }
      }
    };
  }

  private updateChart(): void {
    this.optionsSubject.next({
      legend: this.buildOptionLegend(),
      dataZoom: [
        {
          type: 'slider',
          start: 0,
          end: 100,
          zlevel: -1
        }
      ],
      grid: {
        left: '5%',
        right: '2%',
        bottom: '2%',
        containLabel: true
      },
      tooltip: {
        trigger: 'axis',
        valueFormatter: (value: OptionDataValue | OptionDataValue[]): string => {
          if (Array.isArray(value)) {
            return value.map((it: OptionDataValue) => this.formatTooltipValue(it)).join(', ');
          } else {
            return this.formatTooltipValue(value);
          }
        }
      },
      xAxis: {
        type: 'time'
      },
      yAxis: this.buildOptionYAxis(),
      series: this.series
    });
  }

  private drawChart(): void {
    this.hasError = false;
    this.series = [];
    this.drawSimulatedNotifications();
    this.drawNotifications();
    this.drawHelpLine();
    this.drawDatasetBasedEvents();
  }

  private composeToolTipHtml(notification: IPushNotification, isSimulation: boolean): string {
    if (notification) {
      return `<div style="width: 500px;word-break: break-word;white-space: normal; margin:16px;">
                        <div style="display: flex;justify-content: space-between; align-items: center;">
                            <span style="font-size:20px;font-weight:lighter;">
                                ${isSimulation ? "Simulated Notification" : "Notification"}
                            </span>
                            <span style="text-align: right;font-weight:300; margin:0;font-size:12px;">
                                ${new Date(notification.createdAtTimeMillis).toLocaleString()}<br/>
                                ${notification?.delivery_status?.status === MoostRulesDataGraphComponent.DELIVERY_STATUS_DROPPED ? notification?.delivery_status?.reason : ""}
                            </span>
                        </div>
                        <hr style="margin-top: 16px;">
                        <p style="font-weight:500;margin-bottom:0;">${notification.title.de}</p>
                        <p style="width: 500px;margin-top:0;">
                            ${notification.message.de}<br/>
                        </p>
                        <p>
                            <span style="margin-right:24px;">${notification.actions[0].text.de}</span>
                            <span>${notification.actions[1].text.de}</span>
                        </p>
                        <hr>
                        <p style="font-weight:500;margin-bottom:0;">${notification.title.en}</p>
                        <p style="width: 500px;margin-top:0;">
                            ${notification.message.en}<br/>
                        </p>
                        <p>
                            <span style="margin-right:24px;">${notification.actions[0].text.en}</span>
                            <span>${notification.actions[1].text.en}</span>
                        </p>
                        <hr>
                        <p style="font-weight:500;margin-bottom:0;">${notification.title.fr}</p>
                        <p style="width: 500px;margin-top:0;">
                            ${notification.message.fr}<br/>
                        </p>
                        <p>
                            <span style="margin-right:24px;">${notification.actions[0].text.fr}</span>
                            <span>${notification.actions[1].text.fr}</span>
                        </p>
                    </div>`;
    } else {
      console.error("The notification object to display in the tooltip was undefined");
      return "";
    }
  }

  private getNotificationScatterSeriesOption(type: "DELIVERED" | "DROPPED",
                                             notifications: IPushNotification[],
                                             isSimulation: boolean): ScatterSeriesOption {
    let seriesId: string;
    let seriesHexColor: string;
    let seriesName: string = `${MoostRulesDataGraphComponent.NOTIFICATIONS_SERIES_NAME} - `
    let yAxisValue: number = isSimulation ? 1.85 : 2;

    if (type === "DELIVERED" && !isSimulation) {
      seriesId = MoostRulesDataGraphComponent.DELIVERED_NOTIFICATIONS_SERIES_ID;
      seriesName += `${notifications.length} ${MoostRulesDataGraphComponent.DELIVERY_STATUS_DELIVERED}`;
      seriesHexColor = MoostRulesDataGraphComponent.DELIVERED_NOTIFICATIONS_HEX_COLOR;
    } else if (type === "DROPPED" && !isSimulation) {
      seriesId = MoostRulesDataGraphComponent.DROPPED_NOTIFICATIONS_SERIES_ID;
      seriesName += `${notifications.length} ${MoostRulesDataGraphComponent.DELIVERY_STATUS_DROPPED}`;
      seriesHexColor = MoostRulesDataGraphComponent.DROPPED_NOTIFICATIONS_HEX_COLOR;
    } else if (type === "DELIVERED" && isSimulation) {
      seriesId = MoostRulesDataGraphComponent.SIMULATED_DELIVERED_NOTIFICATIONS_SERIES_ID;
      seriesName += `${notifications.length} ${MoostRulesDataGraphComponent.DELIVERY_STATUS_DELIVERED} - Simulated`;
      seriesHexColor = MoostRulesDataGraphComponent.SIMULATED_DELIVERED_NOTIFICATIONS_HEX_COLOR;
    } else if (type === "DROPPED" && isSimulation) {
      seriesId = MoostRulesDataGraphComponent.SIMULATED_DROPPED_NOTIFICATIONS_SERIES_ID;
      seriesName += `${notifications.length} ${MoostRulesDataGraphComponent.DELIVERY_STATUS_DROPPED} - Simulated`;
      seriesHexColor = MoostRulesDataGraphComponent.SIMULATED_DROPPED_NOTIFICATIONS_HEX_COLOR;
    }

    return {
      id: seriesId,
      name: seriesName,
      color: seriesHexColor,
      type: 'scatter',
      symbol: this.svgPathLetterIcon,
      symbolSize: 20,
      symbolKeepAspect: true,
      itemStyle: {
        borderWidth: 0
      },
      tooltip: {
        trigger: 'item',
        confine: true,
        formatter: (d) => this.composeToolTipHtml(d.data.notification, isSimulation)
      },
      yAxisId: MoostRulesDataGraphAxis.NOTIFICATIONS_AXIS.id.toString(),
      data: notifications.map((notification: IPushNotification): any => {
        return {
          name: notification.pushNotificationId,
          value: [new Date(notification.createdAtTimeMillis), yAxisValue],
          notification: notification
        }
      })
    }
  }
}

class DatasetInfo {
  dataset: IDataset;
  min: number;
  max: number;
  yAxisOption: YAXisOption;

  constructor(dataset: IDataset) {
    this.dataset = dataset;
    this.yAxisOption = MoostRulesDataGraphAxis.getYAxisToEventType(dataset.event_types[0]);
  }

  storeMinAndMax(values: number[]): void {
    this.min = Math.min(...values) || 0;
    this.max = Math.max(...values) || 0;
  }
}
