<h2 mat-dialog-title>
  <mat-icon class="icon">
    <span>
      {{icon}}
    </span>
  </mat-icon>
  {{title}}
  <mat-divider></mat-divider>
</h2>

<div mat-dialog-content>
  <p>{{message}}</p>
</div>

<div class="actions" mat-dialog-actions>
  <button (click)="onDismiss()" mat-stroked-button>{{dismiss}}</button>
  <button (click)="onConfirm()" [color]="confirmColor" mat-raised-button>{{confirm}}</button>
</div>
