import {NgModule} from '@angular/core';
import {MoostUsersProfileComponent} from './moost-users-profile/moost-users-profile.component';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [
    MoostUsersProfileComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatCardModule,
    MatDividerModule,
  ]
})
export class UsersModule {
}
