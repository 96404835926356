<div class="card-container">
  <div *ngFor="let fulfillmentPreference of fulfillmentPreferences" class="card-item">
    <mat-card *ngIf="fulfillmentPreference.type === 'REST'">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon>token</mat-icon>
        </div>
        <mat-card-title>{{ fulfillmentPreference.restAuthStrategy }}</mat-card-title>
        <mat-card-subtitle>{{ fulfillmentPreference.type }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <mat-divider></mat-divider>
        <div *ngIf="updateForm === false || idToUpdate !== fulfillmentPreference.id">
          <div class="attribute-container">
            <div class="attribute-name-item">Preference Id</div>
            <div class="attribute-value-item">{{ fulfillmentPreference.id }}</div>
          </div>
          <div *ngIf="fulfillmentPreference.restAuthStrategy === FulfillmentPreference.REST_AUTH_STRATEGY_APITOKEN"
               class="attribute-container">
            <div class="attribute-name-item">API Token</div>
            <div class="attribute-value-item">{{ fulfillmentPreference.restApiToken | slice:0:20 }}...
              <mat-icon (click)="copyToClipboard(fulfillmentPreference.restApiToken)" class="copy"
                        matTooltip="copy the full token">
                content_copy
              </mat-icon>
            </div>
          </div>
          <div *ngIf="fulfillmentPreference.restAuthStrategy === FulfillmentPreference.REST_AUTH_STRATEGY_BEARER"
               class="attribute-container">
            <div class="attribute-name-item">Bearer Token</div>
            <div class="attribute-value-item">{{ fulfillmentPreference.restBearerToken | slice:0:20 }}...
              <mat-icon (click)="copyToClipboard(fulfillmentPreference.restBearerToken)" class="copy"
                        matTooltip="copy the full token">
                content_copy
              </mat-icon>
            </div>
          </div>
          <div *ngIf="fulfillmentPreference.restAuthStrategy === FulfillmentPreference.REST_AUTH_STRATEGY_BASIC"
               class="attribute-container">
            <div class="attribute-name-item">Auth User</div>
            <div class="attribute-value-item">{{ fulfillmentPreference.restBasicAuthUser }}</div>
          </div>
          <div *ngIf="fulfillmentPreference.restAuthStrategy === FulfillmentPreference.REST_AUTH_STRATEGY_BASIC"
               class="attribute-container">
            <div class="attribute-name-item">Password</div>
            <div class="attribute-value-item">*****
              <mat-icon (click)="copyToClipboard(fulfillmentPreference.restBasicAuthPassword)" class="copy"
                        matTooltip="copy the password">
                content_copy
              </mat-icon>
            </div>
          </div>
          <div class="attribute-container">
            <div class="attribute-name-item">Hostname</div>
            <div class="attribute-value-item">{{ fulfillmentPreference.restHostname }}</div>
          </div>
          <div class="attribute-container">
            <div class="attribute-name-item">Path</div>
            <div class="attribute-value-item">{{ fulfillmentPreference.restPath }}</div>
          </div>
          <div class="attribute-container">
            <div class="attribute-name-item">Is secure</div>
            <div class="attribute-value-item">{{ fulfillmentPreference.restIsSecure }}</div>
          </div>
        </div>
        <app-moost-settings-edit
          (hideUpdateFormEvent)="hideUpdateForm()"
          *ngIf="updateForm === true && idToUpdate === fulfillmentPreference.id"
          [id]=fulfillmentPreference.id
          [restAuthStrategy]=fulfillmentPreference.restAuthStrategy
          [restBasicAuthPassword]=fulfillmentPreference.restBasicAuthPassword
          [restBasicAuthUser]=fulfillmentPreference.restBasicAuthUser
          [restApiToken]=fulfillmentPreference.restApiToken
          [restBearerToken]=fulfillmentPreference.restBearerToken
          [restHostname]=fulfillmentPreference.restHostname
          [restIsSecure]=fulfillmentPreference.restIsSecure
          [restPath]=fulfillmentPreference.restPath
          [type]=fulfillmentPreference.type></app-moost-settings-edit>
      </mat-card-content>
      <mat-card-actions *ngIf="updateForm === false">
        <button (click)="showUpdateForm(fulfillmentPreference.id)"
                [disabled]="!authTokenService.hasPermission(Permission.WRITE_CUSTOMERS)" mat-button>EDIT
        </button>
        <button (click)="confirm(fulfillmentPreference.id)"
                [disabled]="!authTokenService.hasPermission(Permission.WRITE_CUSTOMERS)" mat-button>DELETE
        </button>
      </mat-card-actions>
    </mat-card>
    <mat-card *ngIf="fulfillmentPreference.type === 'EMAIL'">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon>mail</mat-icon>
        </div>
        <mat-card-title>{{ fulfillmentPreference.recipientEmailAddress }}</mat-card-title>
        <mat-card-subtitle>{{ fulfillmentPreference.type }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <mat-divider></mat-divider>
        <div *ngIf="updateForm === false || idToUpdate !== fulfillmentPreference.id">
          <div class="attribute-container">
            <div class="attribute-name-item">Preference Id</div>
            <div class="attribute-value-item">{{ fulfillmentPreference.id }}</div>
          </div>
          <div *ngIf="fulfillmentPreference.userId !== ''" class="attribute-container">
            <div class="attribute-name-item">Building Id</div>
            <div class="attribute-value-item">{{ fulfillmentPreference.userId }}</div>
          </div>
        </div>
        <app-moost-settings-edit (hideUpdateFormEvent)="hideUpdateForm()"
                                 *ngIf="updateForm === true && idToUpdate === fulfillmentPreference.id"
                                 [id]=fulfillmentPreference.id
                                 [recipientEmailAddress]=fulfillmentPreference.recipientEmailAddress
                                 [type]=fulfillmentPreference.type
                                 [userId]=fulfillmentPreference.userId>

        </app-moost-settings-edit>
      </mat-card-content>
      <mat-card-actions *ngIf="updateForm === false">
        <button (click)="showUpdateForm(fulfillmentPreference.id)"
                [disabled]="!authTokenService.hasPermission(Permission.WRITE_CUSTOMERS)" mat-button>EDIT
        </button>
        <button (click)="confirm(fulfillmentPreference.id)"
                [disabled]="!authTokenService.hasPermission(Permission.WRITE_CUSTOMERS)" mat-button>DELETE
        </button>
      </mat-card-actions>
    </mat-card>
    <mat-card *ngIf="fulfillmentPreference.type === 'FIREBASE_CLOUD_MESSAGING'">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon>token</mat-icon>
        </div>
        <mat-card-title>FCM Token</mat-card-title>
        <mat-card-subtitle>Firebase Cloud Messaging</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <mat-divider></mat-divider>
        <div *ngIf="updateForm === false || idToUpdate !== fulfillmentPreference.id">
          <div class="attribute-container">
            <div class="attribute-name-item">Preference Id</div>
            <div class="attribute-value-item">{{ fulfillmentPreference.id }}</div>
          </div>
          <div class="attribute-container">
            <div class="attribute-name-item">FCM Token</div>
            <div class="attribute-value-item">{{ fulfillmentPreference.fcmToken | slice:0:20 }}...
              <mat-icon (click)="copyToClipboard(fulfillmentPreference.fcmToken)" class="copy"
                        matTooltip="copy the full token">
                content_copy
              </mat-icon>
            </div>
          </div>
          <div *ngIf="fulfillmentPreference.userId !== ''" class="attribute-container">
            <div class="attribute-name-item">Building Id</div>
            <div class="attribute-value-item">{{ fulfillmentPreference.userId }}</div>
          </div>
        </div>
        <app-moost-settings-edit (hideUpdateFormEvent)="hideUpdateForm()"
                                 *ngIf="updateForm === true && idToUpdate === fulfillmentPreference.id"
                                 [id]=fulfillmentPreference.id
                                 [type]=fulfillmentPreference.type
                                 [userId]=fulfillmentPreference.userId
                                 [fcmToken]="fulfillmentPreference.fcmToken">

        </app-moost-settings-edit>
      </mat-card-content>
      <mat-card-actions *ngIf="updateForm === false">
        <button (click)="showUpdateForm(fulfillmentPreference.id)"
                [disabled]="!authTokenService.hasPermission(Permission.WRITE_CUSTOMERS)" mat-button>
          EDIT
        </button>
        <button (click)="confirm(fulfillmentPreference.id)"
                [disabled]="!authTokenService.hasPermission(Permission.WRITE_CUSTOMERS)" mat-button>
          DELETE
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
