import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {BehaviorSubject, EMPTY} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {EventsService} from '../../events-module/events.service';
import {IEvent} from '../../events-module/events.models';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-moost-buildings-detail-charts',
  templateUrl: './moost-buildings-detail-charts.component.html',
  styleUrls: ['./moost-buildings-detail-charts.component.scss']
})
export class MoostBuildingsDetailChartsComponent implements OnChanges {

  @Input() startTimestamp: number;
  @Input() endTimestamp: number;
  @Input() customerBuildingId: string = '';
  public isLoading: boolean = false;

  private eventsSubject = new BehaviorSubject([]);
  public events$ = this.eventsSubject.asObservable();

  constructor(public eventsService: EventsService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (Number.isNaN(this.endTimestamp)) {
      this.endTimestamp = moment().toDate().getTime();
    }

    this.loadEvents();
  }

  loadEvents(): void {
    this.isLoading = true;
    this.eventsService.getEventsForCustomerBuilding(
      this.customerBuildingId, parseInt('' + this.startTimestamp / 1000), parseInt('' + this.endTimestamp / 1000)
    ).pipe(
      catchError(() => EMPTY),
      finalize(() => this.isLoading = false)
    ).subscribe((events: IEvent[]) => {
      this.eventsSubject.next(events);
      this.isLoading = false;
    });
  }
}
