<div>
  <div class="filter">
    <app-moost-rules-filter
      (applyFilterChange)="applyFilter($event)">
    </app-moost-rules-filter>
  </div>
  <div class="add-button-container">
    @if (this.allRules) {
      @if (this.allRules.length === this.filteredRules.length) {
        Found {{ this.allRules.length }} rules
      } @else {
        Filter {{ this.filteredRules.length }} of {{ this.allRules.length }} rules
      }
    }
    <a
      [disabled]="!authTokenService.hasPermission(Permission.WRITE_RULES)"
      color="primary" mat-flat-button routerLink="./add"
      style="color: #FFFFFF;">ADD RULE</a>
  </div>
  <div class="card-container">
    @for (rule of filteredRules; track rule.id) {
      <mat-card class="mat-mdc-elevation-specific mat-elevation-z4">
        <mat-card-header>
          <div class="icon-container">
            <mat-icon
              class="topic-color">{{ (RuleIcon.getTopicIcon(rule.topicIcon) || RuleIcon.TOPICS[0]).materialIcon }}
            </mat-icon>
            <mat-icon
              class="impact-color">{{ (RuleIcon.getImpactIcon(rule.impactIcon) || RuleIcon.IMPACTS[0]).materialIcon }}
            </mat-icon>
          </div>
          <div class="feature-content">
            @if (rule.ruleState !== "ACTIVE") {
              <mat-icon matTooltip="Rule not active">toggle_off</mat-icon>
            }
            @if (rule.isTimeBased) {
              <mat-icon [matTooltip]="getTimeBasedCronDescription(rule)">schedule</mat-icon>
            }
            @if (rule.isRestrictedToEarlyAdopters) {
              <mat-icon matTooltip="Restricted to Early Adopters">school</mat-icon>
            }
            @if (rule.streakCondition) {
              <mat-icon matTooltip="Streak">military_tech</mat-icon>
            }
          </div>
        </mat-card-header>
        <mat-card-content>
          <b>{{ rule.name }}</b>
          <p>{{ rule.description }}</p>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button [routerLink]="['./', rule.id, 'edit']" queryParamsHandling="merge">EDIT</button>
        </mat-card-actions>
      </mat-card>
    }
  </div>
</div>
