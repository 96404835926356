export class Rule {
  static readonly ACTIVE: string = 'ACTIVE';
  static readonly PAUSE: string = 'PAUSE';

  id?: string = '';
  timestamp?: number = 0;
  name?: string = '';
  ruleState?: string = Rule.PAUSE;
  resetStateWhenMatched?: boolean;
  customerId?: string = '';
  datasets?: IDataset[];
  isRestrictedToEarlyAdopters?: boolean;
  isTimeBased?: boolean;
  timeBasedCron?: string;
  condition?: string = '';
  streakCondition?: string = '';
  notification?: INotification = new Notification();
  match_threshold?: string = '1';
  time_between_triggers_seconds?: number;
  description?: string = '';
  topicIcon?: string = '';
  impactIcon?: string = '';
  templateRuleId?: string = '';
}

export class Dataset {
  name: string = '';
  description?: string = '';
  type: DatasetType = DatasetType.SINGLEVALUE;
  source_types?: string[] = [];
  event_types: string[] = [];
  timeframe?: number = 0;
}

export class DatasetDialogData {
  dataset: IDataset;
  forbiddenDatasetNames: string[];
  from: number;
  to: number;

  constructor(dataset: IDataset, forbiddenDatasetNames: string[]) {
    this.dataset = dataset;
    this.forbiddenDatasetNames = forbiddenDatasetNames;
  }
}

export interface IDatasetDialogResult {
  datasetKey: string;
  dataset: IDataset;
}

export enum DatasetType {
  SINGLEVALUE = "SINGLEVALUE",
  TIMESERIES = "TIMESERIES"
}

export class RuleIcon {
  public static readonly TOPICS: RuleIcon[] = [
    new RuleIcon("Consumer", "Consumer", "bolt"),
    new RuleIcon("Battery", "Battery", "battery_charging_full"),
    new RuleIcon("Car", "Car", "electric_car"),
    new RuleIcon("CarCharger", "Car Charger", "ev_station"),
    new RuleIcon("HeatPump", "Heat Pump", "heat_pump"),
    new RuleIcon("EnergyMeter", "Energy Meter", "electric_meter"),
    new RuleIcon("SmartPlug", "Smart Plug", "smart_outlet"),
    new RuleIcon("SolarPanel", "Solar Panel", "solar_power"),
    new RuleIcon("Thermostat", "Thermostat", "device_thermostat"),
    new RuleIcon("WaterHeater", "Water Heater", "water_heater"),
    new RuleIcon("Device", "Device", "box"),
    new RuleIcon("Notification", "Notification", "circle_notifications"),
  ]

  public static readonly IMPACTS: RuleIcon[] = [
    new RuleIcon("Home", "Home", "home_work"),
    new RuleIcon("Energy", "Energy", "electric_bolt"),
    new RuleIcon("EnergySavings", "Energy Saving", "energy_savings_leaf"),
    new RuleIcon("Cost", "Cost", "paid"),
    new RuleIcon("Savings", "Savings", "savings"),
    new RuleIcon("Charge", "Charge", "charger"),
    new RuleIcon("Sunny", "Sunny", "sunny"),
    new RuleIcon("Cloudy", "Cloudy", "partly_cloudy_day"),
    new RuleIcon("Cloud", "Cloud", "cloud"),
    new RuleIcon("HeatIncrease", "Heat Increase", "thermometer_gain"),
    new RuleIcon("HeatDecrease", "Heat Decrease", "thermometer_loss"),
    new RuleIcon("Hot", "Hot", "emergency_heat"),
    new RuleIcon("Chart", "Chart", "query_stats"),
    new RuleIcon("TrendUp", "Trend Up", "trending_up"),
    new RuleIcon("TrendDown", "Trend Down", "trending_down"),
    new RuleIcon("Bulb", "Bulb", "emoji_objects"),
    new RuleIcon("CalendarMonth", "Schedule", "calendar_month"),
    new RuleIcon("News", "News", "news"),
    new RuleIcon("Notification", "Notification", "notifications"),
    new RuleIcon("Visibility", "Visibility", "visibility"),
    new RuleIcon("ThumbUp", "Thumb Up", "thumb_up"),
    new RuleIcon("Trophy", "Trophy", "trophy"),
    new RuleIcon("Safety", "Safety", "security"),
    new RuleIcon("Health", "Health", "local_hospital"),
    new RuleIcon("Settings", "Settings", "settings"),
  ]

  constructor(public id: string, public name: string, public materialIcon: string) {
  }

  public static getTopicIcon(id: string): RuleIcon {
    return RuleIcon.byId(id, RuleIcon.TOPICS);
  }

  public static getImpactIcon(id: string): RuleIcon {
    return RuleIcon.byId(id, RuleIcon.IMPACTS);
  }

  private static byId(id: string, list: RuleIcon[]): RuleIcon {
    return list.find((icon: RuleIcon) => icon.id === id);
  }
}

class Notification {
  message?: ITranslatable = new Translatable();
  title?: ITranslatable = new Translatable();
  actions?: INotificationAction[] = [];
}

export class NotificationAction {
  text: ITranslatable;
  actionQualifier: string;
  parameter: ITranslatable;

  constructor(text: ITranslatable, actionQualifier: string, parameter: ITranslatable) {
    this.text = text;
    this.actionQualifier = actionQualifier;
    this.parameter = parameter;
  }
}

class Translatable {
  de?: string = '';
  en?: string = '';
  fr?: string = '';
}

export class DataPoint {
  timestamp: number;
  value: number;
}

export class Streak {
  customerId: string;
  customerBuildingId: string;
  ruleId: string;
  streakCounter: number;
}

export class RulesFilter {
  readonly searchText: string;
  readonly statuses: string[];
  readonly restrictions: string[];
  readonly triggers: string[];
  readonly categories: string[];
  readonly origins: string[];
  readonly eventTypes: string[];
  readonly sourceTypes: string[];

  constructor(searchText?: string,
              statuses?: string[],
              restrictions?: string[],
              triggers?: string[],
              categories?: string[],
              origins?: string[],
              eventTypes?: string[],
              sourceTypes?: string[]) {
    this.searchText = searchText;
    this.statuses = statuses;
    this.restrictions = restrictions;
    this.triggers = triggers;
    this.categories = categories;
    this.origins = origins;
    this.eventTypes = eventTypes;
    this.sourceTypes = sourceTypes;
  }
}

export class RuleVersion {
  id: string;
  timestamp: number;
  rule: Rule;
  username: string;
}

export interface IRule extends Rule {
}

export interface IDataset extends Dataset {
}

export interface ITranslatable extends Translatable {
}

export interface INotification extends Notification {
}

export interface INotificationAction extends NotificationAction {
}

export function secondsToDurationString(seconds: number): string {
  if (!seconds) {
    return "0";
  } else if (seconds % 86400 == 0) {
    return (seconds / 86400 + "d")
  } else if (seconds % 3600 == 0) {
    return (seconds / 3600 + "h")
  } else if (seconds % 60 == 0) {
    return (seconds / 60 + "min")
  } else {
    return seconds + "s"
  }
}

export function stringDurationToSeconds(duration: string): number {
  if (duration == "") {
    return 0;
  } else if (duration.indexOf("d") > 0) {
    return +duration.split("d")[0] * 86400;
  } else if (duration.indexOf("h") > 0) {
    return +duration.split("h")[0] * 3600;
  } else if (duration.indexOf("min") > 0) {
    return +duration.split("min")[0] * 60;
  } else {
    return +duration.split("s")[0];
  }
}
