import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BuildingsRoutingModule} from './buildings-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MoostBuildingsOverviewComponent} from './moost-buildings-overview/moost-buildings-overview.component';
import {MoostBuildingsDetailComponent} from './moost-buildings-detail/moost-buildings-detail.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgxEchartsModule} from 'ngx-echarts';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {SharedModule} from '../shared-module/shared.module';
import {MoostChartsModule} from '../moost-charts/moost-charts.module';
import {NotificationsModule} from '../notifications-module/notifications.module';
import {provideHttpClient, withInterceptorsFromDi, withJsonpSupport} from '@angular/common/http';
import {
  MoostBuildingsDetailChartsComponent
} from './moost-buildings-detail-charts/moost-buildings-detail-charts.component';
import {EventsModule} from '../events-module/events.module';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCardModule} from "@angular/material/card";
import {
  MoostBuildingsConfigurationComponent
} from './moost-buildings-configuration/moost-buildings-configuration.component';
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatListModule} from '@angular/material/list';
import {
  MoostDevicesTableComponent
} from './moost-buildings-configuration/moost-devices-table/moost-devices-table.component';
import {MatChip, MatChipSet} from '@angular/material/chips';
import {
  MoostBuildingsDetailFilterComponent
} from './moost-buildings-detail-filter/moost-buildings-detail-filter.component';
import {MoostBuildingsFilterComponent} from './moost-buildings-filter/moost-buildings-filter.component';
import {MoostBuildingsWaterChartComponent} from './moost-buildings-water-chart/moost-buildings-water-chart.component';
import {
  MoostBuildingsEnergyChartComponent
} from './moost-buildings-energy-chart/moost-buildings-energy-chart.component';
import {
  MoostBuildingsTemperatureForecastChartComponent
} from './moost-buildings-temperature-forecast-chart/moost-buildings-temperature-forecast-chart.component';
import {MatBadge} from "@angular/material/badge";
import {
  MoostBuildingMotivationChartComponent
} from './moost-building-motivation-chart/moost-building-motivation-chart.component';
import {MoostBuildingTypeChartComponent} from './moost-building-type-chart/moost-building-type-chart.component';
import {RulesModule} from "../rules-module/rules.module";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";

@NgModule({
  declarations: [
    MoostBuildingsOverviewComponent,
    MoostBuildingsDetailComponent,
    MoostBuildingsDetailChartsComponent,
    MoostBuildingsConfigurationComponent,
    MoostBuildingsDetailFilterComponent,
    MoostBuildingsFilterComponent,
    MoostBuildingsEnergyChartComponent,
    MoostBuildingsTemperatureForecastChartComponent,
    MoostBuildingsWaterChartComponent,
    MoostDevicesTableComponent,
    MoostBuildingMotivationChartComponent,
    MoostBuildingTypeChartComponent
  ], imports: [
    BuildingsRoutingModule,
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatDatepickerModule,
    MatTooltipModule,
    NgxEchartsModule,
    MatCheckboxModule,
    SharedModule,
    MoostChartsModule,
    NotificationsModule,
    EventsModule,
    MatTabsModule,
    MatCardModule,
    MatOptionModule,
    MatSelectModule,
    MatExpansionModule,
    MatListModule,
    MatChip,
    MatBadge,
    MatChipSet,
    RulesModule,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
  ], providers: [provideHttpClient(withInterceptorsFromDi(), withJsonpSupport())]
})
export class BuildingsModule {
}
