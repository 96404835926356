import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Dataset, DatasetType, IDataset} from './rules.models';
import {ZRLineType} from 'echarts/types/src/util/types';

export enum ColorPalette {
  STATE,
  RATE,
  GENERATION,
  EXCESS,
  CONSUMPTION,
  TEMPERATURE,
  DEFAULT
}

@Injectable({
  providedIn: 'root',
})
export class DatasetsService {
  datasetsSource: BehaviorSubject<IDataset[]> = new BehaviorSubject([]);
  readonly colorPaletteSet: Map<ColorPalette, string[]> = new Map<ColorPalette, string[]>([
    [ColorPalette.STATE, ["#546E7A", "#78909C", "#CFD8DC"]],
    [ColorPalette.RATE, ["#7B1FA2", "#E1BEE7", "#AB47BC"]],
    [ColorPalette.GENERATION, ["#FDD835", "rgba(253,166,53,0.71)", "#FFEE58"]],
    [ColorPalette.EXCESS, ["#00897B", "#80CBC4", "#26A69A"]],
    [ColorPalette.CONSUMPTION, ["#0288D1", "#B3E5FC", "#29B6F6"]],
    [ColorPalette.TEMPERATURE, ["#D32F2F", "#FFCDD2", "#EF5350"]],
    [ColorPalette.DEFAULT, ["#F57C00", "#FFE0B2", "#ffa726"]],
  ]);
  private datasets: IDataset[];

  constructor() {
    this.datasetsSource.subscribe((datasets: IDataset[]): void => {
      this.datasets = datasets;
    });
  }

  public getColorPalette(dataset: Dataset): ColorPalette {
    const eventType: string = dataset.event_types[0];
    if (eventType.includes("RATE")) {
      return ColorPalette.RATE;
    } else if (eventType.includes("STATUS") || eventType.includes("STATE")) {
      return ColorPalette.STATE;
    } else if (eventType.includes("TEMPERATURE")) {
      return ColorPalette.TEMPERATURE;
    } else if (eventType.includes("CONSUMPTION")) {
      return ColorPalette.CONSUMPTION;
    } else if (eventType.includes("GENERATION")) {
      return ColorPalette.GENERATION;
    } else if (eventType.includes("EXCESS")) {
      return ColorPalette.EXCESS;
    } else {
      return ColorPalette.DEFAULT;
    }
  }

  public getHexColorStringForDataset(dataset: Dataset): string {
    const colorPalette: ColorPalette = this.getColorPalette(dataset);
    const colors: string[] = this.colorPaletteSet.get(colorPalette);
    const colorPaletteIndex: number = this.datasets.filter(it => this.getColorPalette(it) === colorPalette)
      .findIndex((it: IDataset): boolean => it.name === dataset.name);
    if (colorPaletteIndex === -1) {
      throw new Error(`No dataset with name '${dataset.name}' found in datasets`);
    }
    if (colorPaletteIndex >= colors.length) {
      console.error("There are not enough colors on the color palette");
    }
    return colors[colorPaletteIndex % colors.length];
  }

  public getLineStyle(dataset: Dataset): ZRLineType {
    const eventType: string = dataset.event_types[0];
    if (eventType.includes('FORECAST')) {
      return 'dotted';
    } else {
      return 'solid';
    }
  }

  public getDatasetMaxTimeframe(datasets: IDataset[]): number {
    let maxTimeframe: number = 0;

    const datasetTimeSeries: IDataset [] = datasets.filter((d: IDataset): boolean => {
      return d.type === DatasetType.TIMESERIES
    });

    if (datasetTimeSeries && datasetTimeSeries.length > 0) {
      const dataset: IDataset = datasetTimeSeries.reduce(
        (prev: IDataset, current: IDataset): IDataset => {
          return (prev && prev.timeframe > current.timeframe) ? prev : current
        });

      maxTimeframe = dataset?.timeframe;
    }

    return maxTimeframe;
  }
}
