<mat-chip>
  <div [style.background-color]="color" matChipAvatar></div>
  <p>
    {{name}}
    <span class="summary">{{summary}}</span>
  </p>
  <button (click)="removeDataset(name)" aria-label="Remove dataset" matChipRemove>
    <mat-icon>cancel</mat-icon>
  </button>
</mat-chip>
