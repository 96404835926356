import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {SettingsService} from '../settings.service';
import {Router} from '@angular/router';
import {EMAIL_PATTERN} from '../../shared-module/moost-form-pattern/email-pattern';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthTokenService} from '../../auth-token-module/auth-token.service';
import {FulfillmentPreference} from '../fulfillment.model';

@Component({
  selector: 'app-moost-settings-add',
  templateUrl: './moost-settings-add.component.html',
  styleUrls: ['./moost-settings-add.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {showError: true},
    },
  ],
})

export class MoostSettingsAddComponent implements OnInit {
  protected readonly FulfillmentPreference = FulfillmentPreference;

  typeForm: FormGroup;
  settingsForm: FormGroup;
  loading: boolean = false;
  submitted: boolean = false;
  restAuthStrategy: string = FulfillmentPreference.REST_AUTH_STRATEGY_BEARER;
  restIsSecure: boolean = false;
  type: string = FulfillmentPreference.TYPE_REST;

  constructor(private formBuilder: FormBuilder, private router: Router, private settingsService: SettingsService,
              private authTokenService: AuthTokenService, private snackBar: MatSnackBar) {
  }

  get f(): any {
    return this.settingsForm.controls;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  next(): void {
    this.settingsForm.controls['type'].setValue(this.type);
    switch (this.type) {
      case FulfillmentPreference.TYPE_REST:
        this.setRestValidators();
        break;
      case FulfillmentPreference.TYPE_FIREBASE_CLOUD_MESSAGING:
        this.setFcmValidators();
        break;
      case FulfillmentPreference.TYPE_EMAIL:
        this.setEmailValidators();
        break;
      default:
        console.log("Unknown type: " + this.type);
    }
  }

  isSecureToggle(event: MatSlideToggleChange): void {
    this.restIsSecure = event.checked;
  }

  changeAuthStrategy(event): void {
    if (event.isUserInput) {
      this.restAuthStrategy = event.source.value;
      this.setRestValidators();
    }
  }

  radioChange(event): void {
    this.type = event.source.value;
  }

  cancel(): void {
    this.router.navigate(['settings']);
  }

  onSubmit(event): void {
    event.preventDefault();
    this.submitted = true;
  }

  create(): void {
    this.loading = true;
    this.settingsService.createFulfillmentPreference(
      this.authTokenService.getCustomerId(),
      this.f.restApiToken.value,
      this.f.restBearerToken.value,
      this.f.restBasicAuthUser.value,
      this.f.restBasicAuthPassword.value,
      this.f.restHostname.value,
      this.f.restPath.value,
      this.f.restIsSecure.value,
      this.f.restAuthStrategy.value,
      this.f.type.value,
      this.f.recipientEmailAddress.value,
      this.f.userId.value,
      this.f.fcmToken.value
    ).subscribe({
      next: (): void => {
        this.loading = false;
        this.snackBar.open('The new preference was successfully saved.', '', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.router.navigate(['settings']);
      },
      error: (error): void => {
        this.loading = false;
        this.settingsForm.get('id').setErrors({error: error.status});
        if (error.status >= 400) {
          this.snackBar.open('The new preference could not be saved.', '',
            {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
        }
      }
    });
  }

  private buildForm(): void {
    this.typeForm = this.formBuilder.group({
      type: [this.type, Validators.required],
    });

    this.settingsForm = this.formBuilder.group({
      fcmToken: '',
      restApiToken: '',
      restBearerToken: '',
      restBasicAuthUser: '',
      restBasicAuthPassword: '',
      restHostname: '',
      restPath: '',
      restAuthStrategy: this.restAuthStrategy,
      restIsSecure: this.restIsSecure,
      recipientEmailAddress: '',
      userId: '',
      type: this.type
    });
  }

  private clearValidators(): void {
    for (const key in this.settingsForm.controls) {
      const formElement = this.settingsForm.get(key);
      if (formElement instanceof FormControl) {
        formElement.clearValidators();
        formElement.updateValueAndValidity();
      }
    }
  }

  private setRestValidators(): void {
    const restApiToken = this.settingsForm.controls['restApiToken'];
    const restBearerToken = this.settingsForm.controls['restBearerToken'];
    const restBasicAuthUser = this.settingsForm.controls['restBasicAuthUser'];
    const restBasicAuthPassword = this.settingsForm.controls['restBasicAuthPassword'];
    const restHostname = this.settingsForm.controls['restHostname'];
    const restPath = this.settingsForm.controls['restPath'];
    const restAuthStrategy = this.settingsForm.controls['restAuthStrategy'];

    // set validators
    this.clearValidators();
    restAuthStrategy.setValidators(Validators.required);
    restHostname.setValidators(Validators.required);
    restPath.setValidators(Validators.required);
    restApiToken.setValidators(this.restAuthStrategy === FulfillmentPreference.REST_AUTH_STRATEGY_APITOKEN ? [Validators.required] : []);
    restBearerToken.setValidators(this.restAuthStrategy === FulfillmentPreference.REST_AUTH_STRATEGY_BEARER ? [Validators.required] : []);
    restBasicAuthUser.setValidators(this.restAuthStrategy === FulfillmentPreference.REST_AUTH_STRATEGY_BASIC ? [Validators.required] : []);
    restBasicAuthPassword.setValidators(this.restAuthStrategy === FulfillmentPreference.REST_AUTH_STRATEGY_BASIC ? [Validators.required] : []);

    restAuthStrategy.updateValueAndValidity();
    restHostname.updateValueAndValidity();
    restPath.updateValueAndValidity();
    restApiToken.updateValueAndValidity();
    restBearerToken.updateValueAndValidity();
    restBasicAuthUser.updateValueAndValidity();
    restBasicAuthPassword.updateValueAndValidity();
  }

  private setEmailValidators(): void {
    const recipientEmailAddress = this.settingsForm.controls['recipientEmailAddress'];
    const userId = this.settingsForm.controls['userId'];

    // set validators
    this.clearValidators();
    recipientEmailAddress.setValidators([Validators.required, Validators.email, Validators.pattern(EMAIL_PATTERN)]);
    userId.setValidators([Validators.required]);

    recipientEmailAddress.updateValueAndValidity();
    userId.updateValueAndValidity();
  }

  private setFcmValidators(): void {
    const fcmToken = this.settingsForm.controls['fcmToken'];
    const userId = this.settingsForm.controls['userId'];

    // set validators
    this.clearValidators();
    fcmToken.setValidators([Validators.required]);
    userId.setValidators([Validators.required]);

    fcmToken.updateValueAndValidity();
    userId.updateValueAndValidity();
  }
}
