import {Component, OnDestroy, OnInit} from '@angular/core';
import {MoostHeaderService} from '../../moost-header/moost-header.service';
import {Permission} from '../../auth-token-module/auth-token.models';
import {AuthTokenService} from '../../auth-token-module/auth-token.service';
import {RulesService} from '../rules.service';
import {catchError, finalize} from 'rxjs/operators';
import {EMPTY, Subscription} from 'rxjs';
import {Building} from '../../buildings-module/buildings.models';
import {IDataset, IRule, RuleIcon, RulesFilter} from '../rules.models';
import cronstrue from 'cronstrue';
import {
  CATEGORY_STREAK,
  RESTRICTION_EARLY_ADOPTER,
  TRIGGER_TIME_BASED
} from '../moost-rules-filter/moost-rules-filter.component';

@Component({
  selector: 'app-moost-rules-overview',
  templateUrl: './moost-rules-overview.component.html',
  styleUrls: ['./moost-rules-overview.component.scss'],
})
export class MoostRulesOverviewComponent implements OnInit, OnDestroy {
  filter: RulesFilter;
  allRules: IRule[];
  filteredRules: IRule[];
  isLoadingRules: boolean;
  protected readonly Permission = Permission;
  protected readonly RuleIcon = RuleIcon;
  private loadRulesSubscription: Subscription;

  constructor(protected authTokenService: AuthTokenService,
              private headerService: MoostHeaderService,
              private rulesService: RulesService) {
    this.headerService.setTitle('Rules');
  }

  ngOnInit(): void {
    this.loadAllRules();
  }

  ngOnDestroy(): void {
    this.loadRulesSubscription?.unsubscribe();
  }

  applyFilter(filter: RulesFilter): void {
    this.filter = filter;
    if (this.allRules) {
      this.setFilter(filter);
    }
  }

  setFilteredRules(rules: IRule[]): void {
    this.filteredRules = rules;
  }

  protected getTimeBasedCronDescription(rule: IRule): string {
    return "Timer " + cronstrue.toString(rule.timeBasedCron, {use24HourTimeFormat: true}).replace(RegExp('^At '), 'at ');
  }

  private setFilter(filter: RulesFilter): void {
    let rules: IRule[] = this.allRules;
    if (filter.searchText) {
      const searchTexts: string[] = filter.searchText.toLowerCase().split(" ");
      rules = rules.filter((rule: IRule) => searchTexts.every(text =>
        rule.name.toLowerCase()?.includes(text) ||
        rule.description?.toLowerCase()?.includes(text) ||
        rule.id?.toLowerCase()?.includes(text)));
    }
    if (filter.statuses?.length > 0) {
      rules = rules.filter((rule: IRule) => filter.statuses.includes(rule.ruleState));
    }
    if (filter.restrictions?.length === 1) {
      const isRestricted: boolean = filter.restrictions[0] === RESTRICTION_EARLY_ADOPTER;
      rules = rules.filter((rule: IRule) => rule.isRestrictedToEarlyAdopters === isRestricted);
    }
    if (filter.triggers?.length === 1) {
      const isTimeBased: boolean = filter.triggers[0] === TRIGGER_TIME_BASED;
      rules = rules.filter((rule: IRule) => rule.isTimeBased === isTimeBased);
    }
    if (filter.categories?.length === 1) {
      const isStreak: boolean = filter.categories[0] === CATEGORY_STREAK;
      rules = rules.filter((rule: IRule) => (!!rule.streakCondition) === isStreak);
    }
    if (filter.eventTypes?.length > 0) {
      rules = rules.filter((rule: IRule) => filter.eventTypes.every((eventType: string) => this.getEventTypes(rule).includes(eventType)));
    }
    if (filter.sourceTypes?.length > 0) {
      rules = rules.filter((rule: IRule) => filter.sourceTypes.every((sourceType: string) => this.getSourceTypes(rule).includes(sourceType)));
    }
    this.setFilteredRules(rules);
  }

  private loadAllRules(): void {
    this.isLoadingRules = true;
    this.loadRulesSubscription = this.rulesService.getAllRules()
      .pipe(
        catchError(() => EMPTY),
        finalize(() => this.isLoadingRules = false))
      .subscribe((rules: Building[]): void => {
        this.allRules = rules;
        this.setFilter(this.filter);
      });
  }

  private getEventTypes(rule: IRule): string[] {
    return [...new Set(rule.datasets.flatMap(((ds: IDataset) => ds.event_types)))];
  }

  private getSourceTypes(rule: IRule): string[] {
    return [...new Set(rule.datasets.flatMap(((ds: IDataset) => ds.source_types)))];
  }
}
