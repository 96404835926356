import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {IEvent} from '../../events-module/events.models';
import {MoostChartsService} from '../../moost-charts/moost-charts.service';

@Component({
  selector: 'app-moost-building-temperature-forecast-chart',
  templateUrl: './moost-buildings-temperature-forecast-chart.component.html'
})
export class MoostBuildingsTemperatureForecastChartComponent implements OnChanges {
  @Input() events: IEvent[];
  @Input() title: string;
  @Input() isLoading: boolean;
  @Input() endTimestamp: number;
  @Input() startTimestamp: number;

  private buildingTemperatureForecastSubject = new BehaviorSubject({});
  public buildingTemperatureForecast$ = this.buildingTemperatureForecastSubject.asObservable();

  constructor(private chartsService: MoostChartsService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.transformEvents(this.events);
  }

  transformEvents(events): void {
    if (events) {
      let buildingTemperatureForecast: any[];

      if (this.endTimestamp - this.startTimestamp <= 432000000) {
        buildingTemperatureForecast = this.chartsService.groupDataForChart(this.events, 'EXPECTED_OUTSIDE_TEMPERATURE', 'hour', null, 'max');
      } else {
        buildingTemperatureForecast = this.chartsService.groupDataForChart(this.events, 'EXPECTED_OUTSIDE_TEMPERATURE', 'day', null, 'max');
      }

      this.buildingTemperatureForecastSubject.next({
        legend: {
          data: ['Forecasted Temperature']
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        xAxis: {
          type: 'category',
          data: Object.keys(buildingTemperatureForecast)
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: 'Forecasted Temperature',
          type: 'line',
          areaStyle: {},
          data: Object.values(buildingTemperatureForecast),
          smooth: true
        }]
      });
    }
  }
}
