export const BUILDING_FILTER_STATUS_ACTIVE: string = 'ACTIVE';
export const BUILDING_FILTER_STATUS_INACTIVE: string = 'INACTIVE';

export class BuildingsFormFilter {
  id: string;
  location: string;
  status: string[];
  eventTypes: string[];
  eventSources: string[];
  eventDateFrom: number;
  eventDateTo: number;
}
