import {YAXisOption} from 'echarts/types/dist/shared';
import {DataUnit} from '../../events-module/events.models';

export class MoostRulesDataGraphAxis {
  static readonly POWER_AXIS: YAXisOption = {
    id: '0Power',
    type: 'value',
    position: 'left',
    boundaryGap: ["4%", "10%"],
    axisLine: {
      onZero: false
    },
    axisLabel: {
      formatter: '{value} W'
    }
  }

  static readonly ENERGY_AXIS: YAXisOption = {
    id: '1Energy',
    type: 'value',
    position: 'left',
    boundaryGap: ["4%", "10%"],
    axisLine: {
      onZero: false
    },
    axisLabel: {
      formatter: '{value} Wh'
    }
  }

  static readonly TEMPERATURE_AXIS: YAXisOption = {
    id: "2Temperature",
    type: 'value',
    position: 'left',
    boundaryGap: ["4%", "10%"],
    axisLine: {
      onZero: false
    },
    axisLabel: {
      formatter: '{value} °C'
    }
  }

  static readonly PERCENTAGE_AXIS: YAXisOption = {
    id: "3Percentage",
    type: 'value',
    min: 0,
    max: 100,
    position: 'left',
    boundaryGap: ["4%", "10%"],
    axisLine: {
      onZero: false
    },
    axisLabel: {
      formatter: '{value} %'
    }
  }

  static readonly NUMBER_STATE_AXIS: YAXisOption = {
    id: "4NumberState",
    type: 'value',
    interval: 1,
    position: 'left',
    boundaryGap: ["4%", "10%"],
    axisLine: {
      onZero: false
    },
    axisLabel: {
      formatter: '{value}'
    }
  }

  static readonly BOOLEAN_AXIS: YAXisOption = {
    id: "5Boolean",
    type: 'value',
    interval: 1,
    min: 0,
    max: 1,
    position: 'left',
    boundaryGap: ["4%", "10%"],
    axisLine: {
      onZero: false
    },
    axisLabel: {
      formatter: '{value}'
    }
  }

  static readonly NOTIFICATIONS_AXIS: YAXisOption = {
    id: "6Notifications",
    name: 'Notifications',
    show: false,
    type: 'value',
    min: 0,
    max: 2,
    interval: 0.1,
    axisLine: {
      onZero: false
    },
    axisLabel: {
      formatter: '{value}'
    }
  }

  static readonly HELP_LINE_AXIS: YAXisOption = {
    id: "7HelpLine",
    name: 'Help Line',
    show: true,
    type: 'value',
    position: 'right',
    axisLabel: {
      formatter: '{value}'
    },
    splitLine: {
      show: false
    },
    offset: 10
  }

  static getYAxisToEventType(eventType: string): YAXisOption {
    switch (DataUnit.ofEventType(eventType)) {
      case DataUnit.NUMBER:
        return MoostRulesDataGraphAxis.NUMBER_STATE_AXIS;
      case DataUnit.PERCENT:
        return MoostRulesDataGraphAxis.PERCENTAGE_AXIS;
      case DataUnit.CELSIUS:
        return MoostRulesDataGraphAxis.TEMPERATURE_AXIS;
      case DataUnit.WATT:
        return MoostRulesDataGraphAxis.POWER_AXIS;
      case DataUnit.WATT_HOUR:
        return MoostRulesDataGraphAxis.ENERGY_AXIS;
      case DataUnit.BOOLEAN:
        return MoostRulesDataGraphAxis.BOOLEAN_AXIS;
      default:
        return MoostRulesDataGraphAxis.NUMBER_STATE_AXIS;
    }
  }

}
