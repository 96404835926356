import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BuildingsService} from '../buildings.service';
import * as moment from 'moment';
import {MoostHeaderService} from '../../moost-header/moost-header.service';
import {NotificationsFilter} from '../../notifications-module/notifications.models';
import {StreaksService} from '../../rules-module/streaks.service';
import {RulesService} from '../../rules-module/rules.service';
import {DatasetType, Rule, Streak} from '../../rules-module/rules.models';
import {EMPTY, forkJoin, Observable, ObservedValueOf, Subscription} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {IBuilding, TariffHourEntry} from '../buildings.models';
import {DatasetsService} from '../../rules-module/datasets.service';
import {BuildingDetailFormFilter} from '../moost-buildings-detail-filter/building-detail-form-filter';

@Component({
  selector: 'app-moost-buildings-detail',
  templateUrl: './moost-buildings-detail.component.html',
  styleUrls: ['./moost-buildings-detail.component.scss']
})
export class MoostBuildingsDetailComponent implements OnInit, OnDestroy {
  initFilter: BuildingDetailFormFilter;
  filter: BuildingDetailFormFilter;
  isLoadingBuilding: boolean;
  loadBuildingSubscription: Subscription;
  building: IBuilding;
  notificationFilter: NotificationsFilter;
  streaks: Streak[];
  rules: Rule[];
  isLoadingStreaksAndRules: boolean;
  loadStreaksAndRulesSubscription: Subscription;
  displayedColumns: string[] = ["badge", "ruleId", "ruleName"]
  displayedColumnsInactiveRules: string[] = ["ruleId", "ruleName"]
  displayColumnsTariffHours: string[] = ["day", "value"]
  tariffHours: TariffHourEntry[] = [];

  constructor(private headerService: MoostHeaderService,
              private route: ActivatedRoute,
              private buildingsService: BuildingsService,
              private streaksService: StreaksService,
              private rulesService: RulesService,
              private datasetsService: DatasetsService
  ) {
    this.headerService.setHeader('Building Detail', '/buildings');
  }

  ngOnInit(): void {
    this.isLoadingBuilding = true;
    this.route.params.subscribe((params) => {
      const customerBuildingId: string = params['id'];
      this.loadBuildingSubscription = this.buildingsService.getBuilding(customerBuildingId).subscribe({
        next: (building: IBuilding): void => {
          this.building = building;
          this.populateTariffHours(building);
          this.notificationFilter = this.buildNotificationFilter(this.initFilter);
          this.isLoadingBuilding = false;
        },
        error: (error): void => {
          console.error(error)
          this.isLoadingBuilding = false;
        }
      });
      this.isLoadingStreaksAndRules = true;
      this.loadStreaksAndRulesSubscription = forkJoin({
        loadedStreaks: this.streaksService.getStreaksByCustomerBuildingId(customerBuildingId),
        loadedRules: this.rulesService.getAllRules()
      }).pipe(
        catchError(() => EMPTY),
        finalize(() => this.isLoadingStreaksAndRules = false)
      ).subscribe((results: {
        loadedStreaks: ObservedValueOf<Observable<Streak[]>>
        loadedRules: ObservedValueOf<Observable<Rule[]>>;
      }): void => {
        this.streaks = results.loadedStreaks;
        this.rules = results.loadedRules;
        this.notificationFilter = this.buildNotificationFilter(this.initFilter);
      });
    });

    this.route.queryParams.subscribe((queryParams) => {
      const paramStartTimestamp: number = queryParams['s'];
      const paramEndTimestamp: number = queryParams['e'];
      const paramDeliveryStatus: string[] = queryParams['deliveryStatus']?.length > 0 ? queryParams['deliveryStatus'].split(",") : null;
      this.initFilter = new BuildingDetailFormFilter(
        paramStartTimestamp || moment().startOf('day').subtract({days: 14}).toDate().getTime(),
        paramEndTimestamp || moment().endOf('day').toDate().getTime(),
        paramDeliveryStatus || BuildingDetailFormFilter.DEFAULT_DELIVERY_STATUSES,
      );
      this.applyFilter(this.initFilter);
    });

    this.datasetsService.datasetsSource.next([
      {
        name: "Power Consumption",
        type: DatasetType.SINGLEVALUE,
        event_types: ["POWER_CONSUMPTION"],
        source_types: ["GATEWAY"]
      },
      {
        name: "Power Consumption Forecast 1H",
        type: DatasetType.SINGLEVALUE,
        event_types: ["POWER_CONSUMPTION_FORECAST_1H"],
      },
      {
        name: "Power Generation",
        type: DatasetType.SINGLEVALUE,
        event_types: ["POWER_GENERATION"],
        source_types: ["GATEWAY"]
      },
      {
        name: "Power Generation Forecast 1H",
        type: DatasetType.SINGLEVALUE,
        event_types: ["POWER_GENERATION_FORECAST_1H"],
        source_types: ["GATEWAY"]
      },
      {
        name: "Grid Power Consumption",
        type: DatasetType.SINGLEVALUE,
        event_types: ["GRID_POWER_CONSUMPTION"],
        source_types: ["GATEWAY"]
      },
      {
        name: "Power Consumption Base Load",
        type: DatasetType.SINGLEVALUE,
        event_types: ["POWER_CONSUMPTION_BASE_LOAD"],
        source_types: ["MOOST"]
      }
    ]);
  }

  ngOnDestroy(): void {
    this.loadBuildingSubscription?.unsubscribe();
    this.loadStreaksAndRulesSubscription?.unsubscribe();
  }


  applyFilter($event: BuildingDetailFormFilter): void {
    this.filter = $event;
    this.notificationFilter = this.buildNotificationFilter(this.filter);
  }

  getRuleName(ruleId: string): string {
    if (ruleId && this.rules) {
      return this.rules.find(it => it.id === ruleId)?.name || ruleId;
    }
  }

  private buildNotificationFilter(filter: BuildingDetailFormFilter): NotificationsFilter {
    return new NotificationsFilter(
      filter?.startTimestampMillis,
      filter?.endTimestampMillis,
      [this.building?.customerBuildingId],
      null,
      this.rules,
      filter.deliveryStatuses,
      null);
  }

  private populateTariffHours(building: IBuilding): void {
    const newTariffHours: TariffHourEntry[] = [];

    newTariffHours.push({
      day: "Monday",
      value: building?.settings.lowTariffHourSetting.mondayEndTime + " - " + building?.settings.lowTariffHourSetting.mondayStartTime
    });
    newTariffHours.push({
      day: "Tuesday",
      value: this.building?.settings.lowTariffHourSetting.tuesdayEndTime + " - " + this.building?.settings.lowTariffHourSetting.tuesdayStartTime
    });
    newTariffHours.push({
      day: "Wednesday",
      value: this.building?.settings.lowTariffHourSetting.wednesdayEndTime + " - " + this.building?.settings.lowTariffHourSetting.wednesdayStartTime
    });
    newTariffHours.push({
      day: "Thursday",
      value: this.building?.settings.lowTariffHourSetting.thursdayEndTime + " - " + this.building?.settings.lowTariffHourSetting.thursdayStartTime
    });
    newTariffHours.push({
      day: "Friday",
      value: this.building?.settings.lowTariffHourSetting.fridayEndTime + " - " + this.building?.settings.lowTariffHourSetting.fridayStartTime
    });
    newTariffHours.push({
      day: "Saturday",
      value: this.building?.settings.lowTariffHourSetting.saturdayEndTime + " - " + this.building?.settings.lowTariffHourSetting.saturdayStartTime
    });
    newTariffHours.push({
      day: "Sunday",
      value: this.building?.settings.lowTariffHourSetting.sundayEndTime + " - " + this.building?.settings.lowTariffHourSetting.sundayStartTime
    });

    this.tariffHours = newTariffHours;
  }

}
