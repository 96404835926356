<app-moost-rules-error *ngIf="!isLoadingRule && errorCode" [errorCode]="errorCode">
</app-moost-rules-error>

<form (ngSubmit)="onSubmit()" *ngIf="!isLoadingRule && !errorCode" [formGroup]="ruleForm">

  <div class="toolbar">
    <app-moost-buildings-selector [(selectedCustomerBuildingId)]="selectedCustomerBuildingId"
                                  [datasets]="this.datasets">
    </app-moost-buildings-selector>

    <mat-form-field appearance="outline">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [formGroup]="selectedDateRange" [max]="maxDate" [rangePicker]="picker">
        <input formControlName="startDate" matStartDate
               placeholder="Start date">
        <input (dateChange)="dateChanged($event)" formControlName="endDate" matEndDate
               placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <button [matMenuTriggerFor]="ruleContextMenu" mat-icon-button type="button">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #ruleContextMenu="matMenu">
      <button (click)="deleteRule()" mat-menu-item>
        <mat-icon>delete</mat-icon>
        <span>Delete</span>
      </button>
      <button (click)="exportRule()" mat-menu-item>
        <mat-icon>upload</mat-icon>
        <span>Export to Clipboard</span>
      </button>
      <button (click)="openImportRuleDialog()" mat-menu-item>
        <mat-icon>download</mat-icon>
        <span>Import from Clipboard</span>
      </button>
    </mat-menu>
  </div>

  <div class="rules-data-graph-container">
    <app-moost-rules-data-graph [customerBuildingId]="selectedCustomerBuildingId"
                                [endTimeRangeMillis]="this.endTimestampMillis"
                                [isSimulationRunning]="this.isSimulationRunning"
                                [ruleId]="rule?.id"
                                [simulatedNotifications]="simulatedNotifications"
                                [startTimeRangeMillis]="this.startTimestampMillis"
                                class="rules-data-graph">
    </app-moost-rules-data-graph>
  </div>

  <div class="page-content">
    <div class="form-container">
      <div class="dataset-list-container">
        <app-moost-dataset-list [from]="this.startTimestampMillis"
                                [to]=this.endTimestampMillis formControlName="datasets">
        </app-moost-dataset-list>
        <mat-error *ngIf="ruleForm.controls.datasets.touched && ruleForm.controls.datasets.invalid
                   && ruleForm.controls.datasets.errors?.['required']">
          Please add at least one dataset.
        </mat-error>
      </div>

      <div class="state-toggle-row">
        <mat-slide-toggle (change)="setRuleState($event)"
                          [checked]="rule?.ruleState === 'ACTIVE'"
                          color="primary">
          Active
        </mat-slide-toggle>

        <mat-slide-toggle color="primary"
                          formControlName="resetStateWhenMatched">
          Reset state when matched
        </mat-slide-toggle>

        <mat-slide-toggle color="primary"
                          formControlName="isRestrictedToEarlyAdopters">
          Restricted to Early Adopters
        </mat-slide-toggle>

        <mat-slide-toggle (change)="isTimeBasedChanged($event)"
                          color="primary"
                          formControlName="isTimeBased">
          Time-based rule
        </mat-slide-toggle>

        <mat-slide-toggle (change)="isStreakChanged($event)"
                          color="primary"
                          formControlName="isStreak">
          Streak
        </mat-slide-toggle>
      </div>


      <mat-form-field [@toggleFormVisibility]="ruleForm.controls.isTimeBased.value ? 'open' : 'closed'"
                      appearance="outline">
        <mat-label>Time-based trigger (Cron notation)</mat-label>
        <input formControlName="timeBasedCron" matInput>
        @if (ruleForm.controls.timeBasedCron.touched && ruleForm.controls.timeBasedCron.invalid) {
          <mat-error>
            {{ ruleForm.controls.timeBasedCron.errors.cronFormat }}
          </mat-error>
        }
        @if (ruleForm.controls.isTimeBased.value && ruleForm.controls.timeBasedCron.valid) {
          <mat-hint>
            Runs {{ cronstrue.toString(ruleForm.controls.timeBasedCron.value, {use24HourTimeFormat: true}).replace(RegExp('^At '), 'at ') }}
            (Central European Time)
          </mat-hint>
        }
      </mat-form-field>

      <mat-form-field appearance="outline" class="monaco-form-field">
        <mat-label>Condition</mat-label>
        <!-- https://github.com/angular/angular/issues/14057 -->
        <!--suppress TypeScriptValidateTypes -->
        <app-rule-language-form-field
          [allowedResultStructures]="[TermStructure.SCALAR]"
          [allowedResultTypes]="[TermType.BOOLEAN]"
          [formControl]="ruleForm.get('condition')">
        </app-rule-language-form-field>
      </mat-form-field>

      <mat-form-field [@toggleFormVisibility]="ruleForm.controls.isStreak.value ? 'open' : 'closed'"
                      appearance="outline">
        <mat-label>Streak Condition</mat-label>
        <!-- https://github.com/angular/angular/issues/14057 -->
        <!--suppress TypeScriptValidateTypes -->
        <app-rule-language-form-field
          [allowedResultStructures]="[TermStructure.SCALAR]"
          [allowedResultTypes]="[TermType.BOOLEAN]"
          [formControl]="ruleForm.get('streakCondition')"
        >
        </app-rule-language-form-field>
      </mat-form-field>

      <div class="form-row-container">
        <mat-form-field appearance="outline" class="grow-fast">
          <mat-label>Name</mat-label>
          <input formControlName="name" matInput>
          <mat-error *ngIf="ruleForm.controls.name.touched && ruleForm.controls.name.invalid">
            Invalid name.
            <span *ngIf="ruleForm.controls?.name.errors?.forbiddenName">A rule with this name exists already.</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="icon-choice">
          <mat-label>Topic</mat-label>
          <mat-select formControlName="topicIcon">
            <mat-select-trigger>
              <mat-icon [matTooltip]="getTopicIcon()?.name"
                        class="icon-image-selected topic-color" matTooltipPosition="left">
                {{ getTopicIcon()?.materialIcon }}
              </mat-icon>
            </mat-select-trigger>
            @for (icon of RuleIcon.TOPICS; track icon.id) {
              <div>
                <mat-option [matTooltip]="icon.name" matTooltipPosition="left" [value]="icon.id">
                  <mat-icon class="icon-image-choice topic-color">{{ icon.materialIcon }}</mat-icon>
                </mat-option>
              </div>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="icon-choice">
          <mat-label>Impact</mat-label>
          <mat-select formControlName="impactIcon">
            <mat-select-trigger>
              <mat-icon [matTooltip]="getImpactIcon()?.name"
                        class="icon-image-selected impact-color" matTooltipPosition="left">
                {{ getImpactIcon()?.materialIcon }}
              </mat-icon>
            </mat-select-trigger>
            @for (icon of RuleIcon.IMPACTS; track icon.id) {
              <mat-option [matTooltip]="icon.name" matTooltipPosition="left" [value]="icon.id">
                <mat-icon class="icon-image-choice impact-color">{{ icon.materialIcon }}</mat-icon>
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea cdkTextareaAutosize="true" formControlName="description" matInput
                  placeholder="Description"></textarea>
        <mat-error *ngIf="ruleForm.controls.description.touched && ruleForm.controls.description.invalid">
          Invalid description
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Match Threshold</mat-label>
        <input formControlName="match_threshold" matInput min="0" placeholder="0" type="number">
        <mat-error
          *ngIf="ruleForm.controls.match_threshold.touched && ruleForm.controls.match_threshold.invalid">
          Invalid value
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Time between Notifications</mat-label>
        <input formControlName="time_between_triggers_seconds" matInput
               placeholder="(Valid values are e.g. 1d, 15h, 50min or 0.)">
        <mat-error
          *ngIf="ruleForm.controls.time_between_triggers_seconds.touched && ruleForm.controls.time_between_triggers_seconds.invalid">
          The value has an invalid format. (Valid values are e.g. 1d, 15h, 50min, 0 etc.)
        </mat-error>
      </mat-form-field>

      <div formGroupName="notification">
        <mat-form-field appearance="outline" class="full-width" formGroupName="primary_action">
          <mat-label>Primary action</mat-label>
          <mat-select [formControlName]="'actionQualifier'">
            <mat-option *ngFor="let actionQualifier of actionSelection" [value]="actionQualifier.id">
              {{ actionQualifier.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width" formGroupName="secondary_action">
          <mat-label>Secondary action</mat-label>
          <mat-select [formControlName]="'actionQualifier'">
            <mat-option *ngFor="let actionQualifier of actionSelection" [value]="actionQualifier.id">
              {{ actionQualifier.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-tab-group (selectedTabChange)="refreshView()" mat-align-tabs="start" mat-stretch-tabs="false">
          <mat-tab *ngFor="let language of activeLanguages | keyvalue" [label]="language.value | uppercase">
            <div class="tab-content">
              <div class="form-row-container">
                <mat-form-field appearance="outline" class="grow-fast">
                  <mat-label>Message Title</mat-label>
                  <input [formControlName]="'title_' + language.key" matInput placeholder="Title">
                </mat-form-field>
                <div class="translate-button-container">
                  <button (click)="translateNotificationTitle(language.key)"
                          mat-icon-button matTooltip="Translate field into other languages"
                          type="button">
                    <mat-icon>translate</mat-icon>
                  </button>
                  <mat-spinner *ngIf="loadingTitleTranslations > 0" [diameter]="40"
                               class="translate-spinner"></mat-spinner>
                </div>
              </div>

              <div class="form-row-container">
                <mat-form-field appearance="outline" class="monaco-form-field grow-fast">
                  <mat-label>Message Text</mat-label>
                  <!-- https://github.com/angular/angular/issues/14057 -->
                  <!--suppress TypeScriptValidateTypes -->
                  <app-rule-language-form-field
                    [allowedResultStructures]="[TermStructure.SCALAR]"
                    [allowedResultTypes]="[TermType.TEXT]"
                    [formControl]="ruleForm.controls.notification.get('message_' + language.key)">
                  </app-rule-language-form-field>
                </mat-form-field>
                <div class="translate-button-container">
                  <button (click)="translateNotificationMessage(language.key)"
                          mat-icon-button matTooltip="Translate field into other languages"
                          type="button">
                    <mat-icon>translate</mat-icon>
                  </button>
                  <mat-spinner *ngIf="loadingMessageTranslations > 0" [diameter]="40"
                               class="translate-spinner"></mat-spinner>
                </div>
              </div>

              <div class="form-row-container" formGroupName="primary_action">
                <mat-form-field appearance="outline" class="grow-slow">
                  <mat-label>Primary action text</mat-label>
                  <input [formControlName]="'text_' + language.key" matInput/>
                </mat-form-field>
                <mat-form-field *ngIf="isActionParameterVisible('primary_action')" appearance="outline"
                                class="grow-fast">
                  <mat-label>Primary action parameter</mat-label>
                  <input [formControlName]="'parameter_' + language.key" matInput/>
                </mat-form-field>

              </div>

              <div class="form-row-container" formGroupName="secondary_action">
                <mat-form-field appearance="outline" class="grow-slow">
                  <mat-label>Secondary action text</mat-label>
                  <input [formControlName]="'text_' + language.key" matInput/>
                </mat-form-field>
                <mat-form-field *ngIf="isActionParameterVisible('secondary_action')" appearance="outline"
                                class="grow-fast">
                  <mat-label>Secondary action parameter</mat-label>
                  <input [formControlName]="'parameter_' + language.key" matInput/>
                </mat-form-field>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>

  <div class="rules-footer-sticky mat-elevation-z3">
    <div class="button-container">
      <button
        [disabled]="!authTokenService.hasPermission(Permission.WRITE_RULES) || !(this.ruleForm.valid && this.ruleForm.dirty)"
        class="mat-flat-button"
        color="primary" mat-flat-button type="submit">
        SAVE
      </button>

      <a class="mat-flat-button" color="accent" mat-flat-button queryParamsHandling="preserve"
         routerLink="../..">CANCEL</a>
      <div class="grow-slow">
        <mat-error *ngIf="createOrUpdateError">{{ createOrUpdateError }}</mat-error>
      </div>
      <button (click)="runSimulation()"
              [disabled]="!authTokenService.hasPermission(Permission.READ_RULES_SIMULATION) || !this.ruleForm.valid || isSimulationRunning"
              class="mat-flat-button"
              color="primary"
              mat-flat-button
              matTooltip="Simulation for selected building
              CTRL + Enter"
              matTooltipClass="multiline"
              type="button">
        RUN SIMULATION
      </button>
    </div>
  </div>
</form>
